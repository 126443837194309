
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../contexts/user.context'

import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
const SummaryList =(props) =>{
    const [isLoad, setLoad] = useState(true);

    const [activelist, setActiveList] = useState([]);
    const { company_id } = useContext(UserContext);
    const [Searchdata, setInputValue] = useState('');

    const [Pagination, setPagination]= useState([]);
    const [isNodata, setNodata] = useState(false);

    const [pendinglist, setPendingList] = useState([]);

    const [open, setOpen] = useState(false)
    const [imageData, setImageData] = useState(null);
    const cancelButtonRef = useRef(null)
    const OpenImage=(image) => {

      setOpen(true);
      console.log(image)
      setImageData(image);
    }

    
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
      
    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setPendingList([]);
      LoadActiveList(newPage);

      console.log(newPage);
    };

    useEffect( () => {
        setLoad(false);

        LoadActiveList();
       
    }, []); 

    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadActiveList();
      };

    const LoadActiveList  = async (page = 1) => {

        const postData = {
          "company_id": company_id,
          "searchdata":Searchdata,
          "status":'active',
          "perpage": 10,
          "type":'expense'
        };
    
        console.log(postData);
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/expense_list', requestOptions)
        .then( async response => 
          {
              console.log(response.status);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
              const data = await response.json();
              console.log(data);  
              // handleData(data.message);
               setNodata(false);
               
            }else{
              const data = await response.json();

              setActiveList(data.data); 
              setPagination(data.pagination);
               props.setshowSummaryCreate(false);
               props.setshowListPending(true);
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
          console.log(data);
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 

    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Expensed</h1>
            <p className="mt-2 text-sm text-gray-700">
              List expense summary
            </p>
          </div>

        </div>
            
        <form onSubmit={handleSubmitSearch}>
            <div class="relative mt-4 w-1/3">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search"
                id="default-search" 
                class="uppercase block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder="Search expense..." 
                value={Searchdata}
                onChange={handleInputChange}
                />
                <button type="submit"   class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
            </div>
        </form>
        <div className= {isLoad? 'mt-2 flow-root uppercase ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>

                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Transaction
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Encoder
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Seller
                      </th>
          
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Tin
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Classification
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Total Amount
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {activelist.map((user) => (
                      <tr key={user.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {user.created_at} 
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.encoder}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.seller}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.tin}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.classification}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{user.totalamount.toLocaleString()}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                           <button   
                            onClick={() => OpenImage(user.image)} class="text-black-600 hover:text-black-900">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>


                            <span class="sr-only">{user.id}</span></button>
                            </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button   
                            onClick={() =>props.GotoSummary(
                            user.created_at,
                            user.encoder,
                            user.seller,
                            user.totalamount,
                            user.tin,
                            user.classification,
                            user.image,
                            user.id,
                            user.user_id,
                            user.expense_item,
                            user.vat_sales,
                            user.vat_amount,
                            user.vat_zero_rated,
                            user.vat_exempt)} class="text-black-600 hover:text-black-900">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>

                            <span class="sr-only">{user.id}</span></button>
                  
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
               
                {activelist.length==0 && isNodata?
                        <div className="rounded-md bg-yellow-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                
                                <div className="mt-2 text-sm text-yellow-700 ">
                                    <p>
                                            Data Not Found
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''}
                  <div className="container mx-auto p-4">
                    <PaginatedTable data={activelist} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                  </div>
                        
              </div>
            </div>
          </div>
        </div>
       
        <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
          
      </div>);

}
export default SummaryList;