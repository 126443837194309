import { useState,useEffect } from 'react'
const StepTaxPayer = (props) => {


  const [typeTax, setTypeTax] = useState('N/A'); 
  const handleChange = (event) => {
    setTypeTax(event.target.value);
  if(event.target.value=='PT'){
  
    props.updateTaxTypes(2, 3);

  }
  if(event.target.value=='VT'){
  
    props.updateTaxTypes(3, 2);
  }
  if(event.target.value=='N/A'){
    props.updateTaxTypesFalseAll(2,3);
  }
  };
    return (
        <>
         <div className="space-y-12 ">
            

                    <div className="border-b border-gray-900/10 pb-4 mt-10">

                 
                      
                      <div className="mt-10 grid grid-cols-12 gap-x-6 gap-y-8 sm:grid-cols-12">
    
                      <div className="col-span-12">
                        <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 2xl:grid-cols-12 gap-2">
                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Trade Name  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="trade_name"
                                  name="trade_name"
                                  type="trade_name"
                                  autoComplete="trade_name"
                                  required
                                  value={props.trade_name}
                                  onChange={props.handleChange}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                           
                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Tin  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="tin"
                                  name="tin"
                                  type="tin"
                                  autoComplete="tin"
                                  required
                                  value={props.tin}
                                  onChange={props.handleChange}
                                  maxLength={15}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                           

                            <div className="sm:col-span-2">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                PSIC  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                id="psic"
                                name="psic"
                                type="psic"
                                autoComplete="psic"
                                required
                                value={props.psic}
                                onChange={props.handleChange}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-4">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Register Address  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                   id="reg_address"
                                   name="reg_address"
                                   type="reg_address"
                                   autoComplete="reg_address"
                                   required
                                   value={props.reg_address}
                                   onChange={props.handleChange}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                 

                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Line of Business  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="line_business"
                                  name="line_business"
                                  type="line_business"
                                  autoComplete="line_business"
                                  required
                                  value={props.line_business}
                                  onChange={props.handleChange}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 ">
                                Top withhold agent
                              </label>
                              <div className="mt-2">
                              <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                  {props.TopAgentRadio.map((RadioItem) => (
                                      <div key={RadioItem.id} className="flex items-center">
                                      <input
                                          id={RadioItem.id}
                                          name="notification-method"
                                          type="radio"
                                          value={RadioItem.id}
                                          checked={props.selectedAgentOption === RadioItem.id.toString()} // Check if the current option is selected
                                          onChange={props.handleRadioAgentChange} // Call the 
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label htmlFor={RadioItem.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                          {RadioItem.title}
                                      </label>
                                      </div>
                                  ))}
                                  </div>
                              </fieldset>
                              </div>
                            </div>
      
                        </div>

                      </div>
                      </div>
                      
                    </div>

                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      <select  
                      className=" p-2.5 block w-full
                      rounded-md border-0 py-1.5 text-gray-900 
                      shadow-sm ring-1 ring-inset ring-gray-300
                      placeholder:text-gray-400 focus:ring-2
                      focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                
                      name="TAXTYPE"
                      value={typeTax} // Bind the value to state
                      onChange={handleChange} // Handle change events
                      >
                      <option value="PT">Percentage Tax (PT)</option>
                      <option value="VT">Value added tax (VT)</option>
                      <option value="N/A">N/A</option>
                      </select>
                  </div>
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      {props.Taxtypes.map((prev) => (

                        (prev.id !=2 && prev.id !=3)?
                          <li key={prev.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                              <div className="flex-1 truncate">
                
                              <p className="mt-1 truncate text-sm text-gray-500 font-bold">{prev.description}</p>
                              </div>
                              <input
                                id={'option' + prev.id}
                                aria-describedby={'option' + prev.id}
                                name={'option' + prev.id}
                                type="checkbox"
                                checked={prev.checked}
                                onChange={() => props.handleCheckboxChangeTax(prev.id)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                          </div>

                          </li>
                            :''
                      ))}
                    </ul>

                  </div>
               
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                    onClick={props.BackTaxPayer}
                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Back
                    </button>
                    <button
                      type="submit"
                      className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Next
                    </button>
                  </div>
                  {props.isErrorPasswordVisible?
                   <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>{props.isErrorPasswordText}</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                  :''
                  }  
                 

        </>
       
        )
    }
    export default StepTaxPayer;