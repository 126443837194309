
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../contexts/user.context'
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
import { formatAmount,formatTin,SentenceCaseText,formatAmountDisplay } from '../../utils/ValidationField';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';


const SalesVerification =(props) =>{


    const [isLoad, setLoad] = useState(true);

    const { positionUser,privelegeUser } = useContext(UserContext);
    const [pendinglist, setPendingList] = useState([]);
    const [Pagination, setPagination]= useState([]);
    const { company_id } = useContext(UserContext);
    const [Searchdata, setInputValue] = useState('');

    const [open, setOpen] = useState(false);
    
    const { user_id } = useContext(UserContext);
    const [OpenModalUpdate, setOpenModalUpdate] = useState(false)
      
    const [ItemID, SetItemID] = useState(0);


    const [allClass, setClass] = useState([]);


    const closeImageModal = () => {

      console.log('xxxxx');
      setOpenImage(false);
      setOpen(true);
  };


    const [OpenImage, setOpenImage] = useState(false);


    const openimage=(image) => {

      setOpenImage(true);
  
        setImageData(image);
      }

   
 
    const { Tax_types } = useContext(UserContext);


    const CheckTax_VAT = ()=>{
      const item =JSON.parse(Tax_types).find(item => item.id === 3);
      return item.checked;
     
    }

 
    const [TransactionType, setTransactionType] = useState('sales');

    
    const defaultFormFields = {
      seller:'',
      particular:'',
      tin: '',
      tin2: '',
      item_description:'',
      price:'',
      classification: '',
      VTclassification: '',
      vat_sales:'',
      vat_gov:'',
      vat_amount:'',
      vat_zero_rated:'',
      vat_exempt:'',
      TxtDate: '',
      TxtOrNum:'',
      TxtParticular:'',
      TxtQuantity:'',
      TxtPrice:'',
      fileupload:'',
      total_amount_due:'',
      address:''
     
    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { fileupload,seller,particular, tin1,tin2,classification,VTclassification,vat_sales,vat_amount,vat_zero_rated,vat_exempt,item_description,TxtDate,TxtOrNum,TxtParticular,TxtQuantity,TxtPrice,total_amount_due,address,vat_gov} = formFields;
    const handleChange = (event) => {
      const { name, value } = event.target;


      
    const formattedValue =
    name === 'amount' ? formatAmount(value) :
    name === 'vat_sales' ? formatAmount(value) :
    name === 'vat_gov' ? formatAmount(value) :
    name === 'vat_amount' ? formatAmount(value) :
    name === 'vat_zero_rated' ? formatAmount(value) :
    name === 'vat_exempt' ? formatAmount(value) :
    name === 'price' ? formatAmount(value):
    name === 'total_amount_due' ? formatAmount(value):
    name === 'tin1' ? formatTin(value) :
    name === 'modalPrice' ? formatAmount(value) :
    name === 'TxtPrice' ? formatAmount(value) :value;
   
    
    
    
      setFormFields({ ...formFields, [name]: formattedValue });

    };


    const UpdateTaxes =  () => {

      const options = { style: 'currency', currency: 'PHP' };


 
      setSpecificItem('vat_sales', (getSum("VT")/1.12).toLocaleString('en-PH', options));
      
      setSpecificItem('vat_gov', (getSum("VT-GOV")/1.12).toLocaleString('en-PH', options));
      setSpecificItem('vat_exempt', (getSum("VT-EX")).toLocaleString('en-PH', options));

      setSpecificItem('vat_zero_rated', (getSum("VT-Z")).toLocaleString('en-PH', options));

      setSpecificItem('vat_amount', ((((getSum("VT")/1.12)*0.12)+((getSum("VT-GOV")/1.12)*0.12))).toLocaleString('en-PH', options));




      setSpecificItem('total_amount_due', ((getSum("VT-GOV")/1.12)+(getSum("VT")/1.12)+getSum("VT-EX")+getSum("VT-Z")+(getSum("VT")/1.12)*0.12).toLocaleString('en-PH', options));
    }



  const setSpecificItem = (itemName, itemValue) => {
    setFormFields(prevState => ({
        ...prevState,
        [itemName]: itemValue
    }));
};





  const [listItem, setListItem] = useState([]);

  const getSum = (classification) => {

    // console.log(classification);
    return listItem
        .filter(item => item.vat_class === classification) // Filter items with the specified classification
        .reduce((acc, item) => acc + parseFloat(item.total), 0); // Sum the "total" values
};
    const LoadClassList = async () => {



      const postData = {
        "search":'',
        "company_id": company_id,
        "type": 'sales',
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
      .then( async response => 
        {
          //  console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
            // console.log(data.data);
            setClass(data.data); 

         
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
      //   setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      // setLoad(true);
  } 




    const cancelButtonRef = useRef(null)
  
    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setPendingList([]);
      LoadPendingList(newPage)

      // console.log(newPage);
    };

    useEffect( () => {
        setLoad(false);

        LoadPendingList();

        setOpenModalUpdate(false);


        setSpecificItem('TxtParticular','')
        setSpecificItem('TxtQuantity','')
        setSpecificItem('TxtPrice','')
  
        setSpecificItem('VTclassification','')
  
        
        console.log(user_id);
      
    },  [listItem]); 

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
  

    const [isNodata, setNodata] = useState(false);

    
    const [imageData, setImageData] = useState(null);


    
    const [Supplimental_Fileupload, setSupplimental_Fileupload] = useState([]);

    const OpenModalItem=(image,seller,tin,classification,ORNUM,transdate,listitem,ItemId,supplemental_item,address) => {

      setOpen(true);
      SetItemID(ItemId);

      setSupplimental_Fileupload(supplemental_item);


      console.log(address);
   
      setImageData(image);

     // setSpecificItem('seller', seller);


      
      setFormFields({
        ...defaultFormFields,
        seller: seller, 
        tin1: tin.substring(0, 11),
        tin2: tin.slice(-3),
        classification:classification,
        TxtOrNum:ORNUM,
        TxtDate:transdate,
        address:address
      });

      // console.log(listitem);

      setListItem(listitem);
      UpdateTaxes();
    }


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadPendingList();
      };
    const LoadPendingList = async (page = 1) => {


    
        const postData = {
          "company_id": company_id,
          "searchdata":Searchdata,
          "status":'pending',
          "perpage": 10,
          "type":'sales',
          "page":page
        };
       
         console.log(postData);
         
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/sales_list', requestOptions)
        .then( async response => 
          {
            //  console.log(response);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               
            }else{
              const data = await response.json();
              console.log(data.data);
               setPendingList(data.data); 
               setPagination(data.pagination);
               props.setshowSummaryCreate(false);
               props.setshowListPending(true);

           
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 

    const deleteItem = (indexToDelete) => {
      // console.log(indexToDelete);
       setListItem(currentItems => currentItems.filter((_, index) => index !== indexToDelete));
       UpdateTaxes();
    
    };
    const AddTable =  () => {
        
      addNewItem();
      // formFields.TxtDate='';
  
      formFields.TxtPrice='';
      formFields.TxtParticular='';
      formFields.TxtQuantity='';


  }

  

    const addNewItem = () => {


      if(TxtParticular=='' || TxtQuantity=='' || TxtPrice.replace(/,/g, '')<0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please complete the data',
            
            })

            return;
        }
      const newItem = { 
      "particular": TxtParticular ,
      "qty": TxtQuantity ,
      "vtclassifcation":VTclassification,
      "price": TxtPrice.replace(/,/g, ''),
      "total":(TxtPrice.replace(/,/g, '') * TxtQuantity)
    }; // Example new item
    setListItem(currentItems => [...currentItems, newItem]);

      
    

    };
    const updateListItem = (index, updatedItem) => {
      setListItem(prevList => {
        const newList = prevList.map((item, i) => {
          if (i === index) {
            return updatedItem; // Replace the item at the specified index
          }
          return item; // Keep other items unchanged
        });
        return newList;
      });
    };


    const UpdateItemSpecific = () => {
      if(TxtParticular=='' || TxtQuantity=='' || TxtPrice.replace(/,/g, '')<0){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please complete the data',
          
          })

          return;
      }
     


      const updatedItem = {
        "particular": formFields.TxtParticular,
        "qty": formFields.TxtQuantity,
        "vat_class": formFields.VTclassification,
        "price": formFields.TxtPrice.replace(/,/g, ''),
        "total": (formFields.TxtQuantity *  formFields.TxtPrice.replace('₱', '').replace(/,/g, ''))
      };
      updateListItem(ModalItemIndex, updatedItem);

     console.log(listItem);
  
     
      //setOpen(false);

    

      
      
    };

    useEffect(() => {
      LoadClassList();
      UpdateTaxes();
    }, [listItem]); // Dependency array includes listItems, so this runs every time listItems changes
  

    
    const [ModalItemIndex, setModalItemIndex] = useState(0);

    const UpdateItem = (index,particular,qty,price,vtclassifcation) => {

      setModalItemIndex(index);

      setSpecificItem('TxtParticular',particular)
      setSpecificItem('TxtQuantity',qty)
      setSpecificItem('TxtPrice',price)

      setSpecificItem('VTclassification',vtclassifcation)

      setOpenModalUpdate(true);
      

      // console.log(vtclassifcation);
    };
      
    const handleConfirmDisapproval = ( reason) => {
      if (!reason) {
        Swal.showValidationMessage("Remark is required!", () => {
          // Custom function to prevent closing the modal
          Swal.getPopup().querySelector('input').focus(); // Focus on the input field
        });
        return false; // Prevents closing the modal
      } else {
      //  DisapprovedExpense(ItemID, reason);
        return true; // Allows closing the modal
      }
    }; 
  
    const Disapproved = () => {

      
      
      Swal.fire({
        title: "Remarks",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          inputAutosubmit: false, // Disable auto-suggestion
        autocomplete: "off" // Disable browser suggestions
        },
        showCancelButton: true,
        confirmButtonText: "Disapprove",
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          // Here, we return the value directly
          handleConfirmDisapproval( value)
          return value;
        },
  
       
        allowOutsideClick: () => !Swal.isLoading(),
        customClass: {
          confirmButton: 'bg-red-500' // Custom class for the confirm button
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const reason = result.value;
          // Log the reason to the console
          console.log("Reason entered:", reason);

       
          DisapprovedExpense(ItemID,reason)

              
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {

          }  
        
        })
     

    
    }


    
    const DisapprovedExpense = async (expense_id,reason) => {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
      
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to delete",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
      

      console.log(user_id);
      const postData = {
        "expense_id":expense_id,
        "reason": reason,
        "user_id":user_id
    
      };
     
      
      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/expense_disapprove', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

           // const data = await response.json();
         
            // handleData(data.message);
          //    setNodata(false);
             
          }else{
            const data = await response.json();
            console.log(data.data);
            // setClass(data.data); 

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Successfully disapprove',
              showConfirmButton: false,
              timer: 1500
          })  
         
          window.location.reload()
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
       // setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
    //  setLoad(true);

    

  } else if (
    /* Read more about handling dismissals below */
    result.dismiss === Swal.DismissReason.cancel
  ) {

  }  

})
  }
  

  const ApprovedItem = async (reason) => {
    

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "Do you want to save",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
    
   
        // setCompletesubmit(true);
        const postData = {
          "expense_id":ItemID,
          "seller": seller,
          "tin": tin1+''+tin2,
          "classification": classification,
          "user_id": user_id,
          "company_id":company_id,
          // "type":selectype,
          "vat_sales":parseFloat(vat_sales.replace('₱', '').replace(/,/g, '')),
          "vat_amount":parseFloat(vat_amount.replace('₱', '').replace(/,/g, '')),
          "vat_zero_rated":parseFloat(vat_zero_rated.replace('₱', '').replace(/,/g, '')),
          "vat_exempt":parseFloat(vat_exempt.replace('₱', '').replace(/,/g, '')),
          "total_amount_due":parseFloat(total_amount_due.replace('₱', '').replace(/,/g, '')),
          "expense_item":  listItem,
          "reason":'ok',
          "ornum":TxtOrNum,
          "address":address
        };



        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };

        await fetch(process.env.REACT_APP_API_URL+'api/expense_approve', requestOptions)
        .then( async response => 
          {
              console.log(response);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded

              const data = await response.json();
          
             // setCompletesubmit(false);
              
            
            }else{
              const data = await response.json();
              console.log(data.message);  
//              setCompletesubmit(false);
              

              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Approve',
                showConfirmButton: false,
                timer: 1500
            })  
            window.location.reload();
            
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
          console.log(data);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {

      }
    })
   
  }; 

    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">For Verification Sales</h1>
     
          </div>

        </div>
            
        <form onSubmit={handleSubmitSearch}>
            <div class="relative mt-4 w-1/3">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search"
                id="default-search" 
                class=" block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder="Search sales..." 
                value={Searchdata}
                onChange={handleInputChange}
                />
                <button type="submit"   class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
            </div>
        </form>
        <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                  
                    {(CheckTax_VAT())?
                        <>
                           <th scope="col" className="whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Total Amount 

                      </th>
                       <th scope="col" className="whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Total Amount Due

                      </th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Vat Sales
                      </th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Vat Goverment
                      </th>
                      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Vat Exempt

                      </th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Vat Zero Rated
                      </th>
                      <th scope="col" className=" whitespace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Vat Amount
                      </th>
                
                      </> : ''
                           }
                     <th scope="col" className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Classification
                      </th>
                     <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                        Buyer
                      </th>
                      <th scope="col" className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                        Encoder
                      </th>
            
    
                      <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Encoded
                      </th>
                      <th scope="col" className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                        Series
                      </th>
                
                      <th scope="col" className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                        Address
                      </th>
                     
                      <th scope="col" className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                        Tin
                      </th>
                  
                      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Invoice No.
                      </th>
                   
                    
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {pendinglist.map((user) => (
                      <tr key={user.id}>
                               
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">

                          {(positionUser !='Encoder' && positionUser !='Bookkeeper')?
                           <button   
                            onClick={() => OpenModalItem(user.image,
                              user.seller,
                              user.tin,
                              user.classification,
                              user.ornum,
                              user.date_trans,
                              user.expense_item,
                              user.id,
                              user.supplemental_item,
                              user.address
                              )} class="text-black-600 hover:text-black-900 mr-2  py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                            <span class="sr-only">{user.id}</span></button>
                              :''

                            }

                         
                            </td>
                    
                          {(CheckTax_VAT())? <>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.totalamount>0)? formatAmountDisplay(user.totalamount) : 0}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.total_amount_due>0)? formatAmountDisplay(user.total_amount_due) : 0}</td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.vat_sales>0)? formatAmountDisplay(user.vat_sales):0}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.vat_gov>0)? formatAmountDisplay(user.vat_gov):0}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.vat_exempt>0)? formatAmountDisplay(user.vat_exempt) :0}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.vat_zero_rated>0)? formatAmountDisplay(user.vat_zero_rated):0}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium">₱{(user.vat_amount>0)? formatAmountDisplay(user.vat_amount):0}</td>
                   
                         </> : ''
                           }
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.classification}</td>
                                         <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500 capitalize">{user.seller}</td>
                                         <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{user.encoder}</td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 ">
                            {user.created_at} 
                        </td>
          
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{user.series}</td>
                        <td className=" px-3 py-4 text-sm text-gray-500">{user.address}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.tin}</td>
                   
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.ornum}</td>

                      
                        
                    
                        
                
                      </tr>
                    ))}
                  </tbody>
                </table>
                {pendinglist.length==0 && isNodata?
                <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                        
                        <div className="mt-2 text-sm text-yellow-700 ">
                            <p>
                                    Data Not Found
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                :
                ''}
                 <div className="container mx-auto p-4">
                  <PaginatedTable data={pendinglist} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">


            
         <Transition.Root show={OpenImage} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeImageModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={closeImageModal}
                                        >
                                            <span className="sr-only">Close</span>
                                            {/* Replace with your close icon */}
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M14.293 5.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414L10 8.586l4.293-4.293z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <div className="mt-2">
                                            {imageData && <img src={imageData} alt="Cached API Image" />}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            
          <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto item-center ">
                        <div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0 mt-10 ">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl md:max-w-3xl lg:max-w-3xl 2xl:max-w-4xl sm:p-6 mx-auto">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                      setOpen(false);
                                      
                                      // Call your second function here
                                  }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>

                                  <h1 className='text-2xl font-semibold leading-6 text-indigo-600 '> Verification  </h1>
                                    <div className="mt-3 text-center sm:mt-5 ">
{/*                             
                                        <div className="mt-2">
                                          
                                            {imageData && <img className='mx-auto'     style={{ maxHeight: '300px', maxWidth: '100%', height: 'auto' }} src={imageData} alt="Cached API Image" />} 
                                        </div> */}


                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                         

                                            <div>
                                              <div className='flex mt-4'>
                                                 <div className='w-2/5	 font-bold text-left'>
                                                 Buyer:
                                                  </div>
                                                  <div className='w-2/5	  text-left capitalize'>
                                                    {seller}
                                                  </div>
                                              </div>
                                            </div>

                                            <div>
                                              <div className='flex mt-4'>
                                                  <div className='w-2/5	 font-bold text-left'>
                                                  Address:
                                                  </div>
                                                  <div className='w-2/5	  text-left capitalize'>
                                                  <SentenceCaseText text={address} />
                                                  </div>
                                              </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-2 gap-4 mt-2">
                                          

                                        <div>
                                                <div className='flex mt-4'>
                                                  <div className='w-2/5 font-bold text-left'>
                                                    Tin:
                                                  </div>
                                                  <div className='w-3/5	text-left '>
                                                  {tin1}{tin2}
                                                    </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div className='flex mt-4'>
                                              <div className='w-2/5	 font-bold text-left'>
                                                  Classification:
                                                  </div>
                                                  <div className='w-2/5	  text-left'>
                                                  {classification}
                                                  </div>
                                              </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-2 gap-4 mt-2">
                                           

                                        <div>
                                                <div className='flex mt-4'>
                                                  <div className='w-2/5	 font-bold text-left'>
                                                    Invoice No:
                                                  </div>
                                                  <div className='w-3/5	text-left '>
                                                     {TxtOrNum}
                                                  </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div className='flex mt-4'>
                                                  <div className='w-2/5	 font-bold text-left'>
                                                  Date:
                                                  </div>
                                                  <div className='w-3/5	text-left '>
                                                  {TxtDate}
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                               


                                        <div className='col-span-full '>
                                            <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>
                                                
                                                    <div className="mt-8 flow-root">
                                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                            <table className="min-w-full divide-y divide-gray-300 ">
                                                            <thead>
                                                                <tr>
                                                            
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Particular / item description
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Quantity
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Price
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Total
                                                                </th>
                                                                {(CheckTax_VAT())?
                                                                <th scope="col" className="px-3 whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                     VAT CLASS
                                                                </th>: ''}
                                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                                    <span className="sr-only">Edit</span>
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white">
                                                                {listItem.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-left">
                                                                 
                                                                              <SentenceCaseText text={item.particular} />
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.qty}</td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(item.price)}</td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(item.total)}</td>
                                                                            {(CheckTax_VAT())?
                                                                            <td className={`whitespace-nowrap px-3 py-4 text-sm ${item.vat_class === '' && 'bg-gray-500' || item.vat_class === 'NVT' && 'bg-gray-500'}  border-white border w-16`}>{(item.vat_class !='NVT')? item.vat_class: ''}</td>: ''}
                                                                          
                                                                        </tr>
                                                                        ))}
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                        </div>
                                                    </div>
                                               
                                                    <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>

                                            </div>


                                            {(TransactionType=='sales' && CheckTax_VAT())? 

                                            <>

                                                <div className="grid grid-cols-2 gap-4 mt-2">
                                                  
                                                  <div>
                                                      <div className='flex mt-4'>
                                                        <div className='w-3/5	 font-bold text-left'>
                                                          Vat Sales
                                                        </div>
                                                        <div className='w-3/5	text-left '>
                                                        {vat_sales}
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                      <div className='flex mt-4'>
                                                        <div className='w-3/5	 font-bold text-left'>
                                                          Vat Goverment
                                                        </div>
                                                        <div className='w-3/5	text-left '>
                                                        {vat_gov}
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className='flex mt-4'>
                                                        <div className='w-3/5 font-bold text-left'>
                                                        Vat Exempt:
                                                        </div>
                                                        <div className='w-3/5	text-left '>
                                                        {vat_exempt}
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                      <div className='flex mt-4'>
                                                        <div className='w-3/5	 font-bold text-left'>
                                                        Vat Zero Rated
                                                        </div>
                                                        <div className='w-3/5	text-left '>
                                                        {vat_zero_rated}
                                                        </div>
                                                    </div>
                                                  </div>

                                              </div>


                                                
                                              <div className="grid grid-cols-2 gap-4 mt-2">
                                           
                                                  <div>
                                                    <div className='flex mt-4'>
                                                        <div className='w-3/5 font-bold text-left'>
                                                        Vat Amount		:
                                                        </div>
                                                        <div className='w-3/5	text-left '>
                                                        {vat_amount}
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                      <div className='flex mt-4'>
                                                        <div className='w-3/5	 font-bold text-left'>
                                                            Total Amount Due	:
                                                            </div>
                                                            <div className='w-3/5	text-left '>
                                                        {total_amount_due}
                                                         
                                                       
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>

                                            </>
                                            
                                            
                                            
                                            :''}
                                      
                                       
                                        <div className="flex justify-center mt-4">
                                          <div className="w-3/6">
                                          <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">Official Receipt</h2>
                                            <div >
                                              <a href="#"  onClick={() => openimage(imageData)}>
                                              <img
                                                src={imageData}
                                               
                                                alt="Uploaded preview"
                                                className="cursor-pointer h-auto w-3/5  mx-auto duration-300 transform hover:scale-125"
                                              />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="w-3/6">
                                            <div >
                                            <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">supplemental</h2>
                                              <ul role="list" className="gap-4 mx-auto grid  grid-cols-1 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                                    {Supplimental_Fileupload.map((img, index) => (
                                                      <li key={index} className="relative">
                                                        {/* Container for image and delete button */}
                                                        <div className="relative">
                                                          {/* Image */}
                                                          <img  onClick={() => openimage(img.imageUrl)} className="cursor-pointer h-auto w-auto  object-cover duration-300 transform hover:scale-125" src={img.imageUrl} alt="" />
                                                          {/* Delete button */}
                                                 
                                                        </div>
                                                      </li>
                                                    ))}
                                                    </ul> 
                                            </div>
                                          </div>
                                        </div>
                                        

                                        <div className="flex items-center justify-end gap-x-4 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                                           <button
                                            type="submit"
                                            onClick={() => {
                                              Disapproved();
                                              
                                              // Call your second function here
                                          }}
                                            className="uppercase rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                            >
                                            Disapproved
                                            </button>
                                            <button
                                            type="submit"
                                            onClick={() => {
                                              ApprovedItem();
                                              
                                              // Call your second function here
                                          }}
                                            className="uppercase rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                            >
                                            Approve
                                            </button>
                                            <button
                                            type="submit"
                                            onClick={() => {
                                              setOpen(false);
                                              
                                              // Call your second function here
                                          }}
                                            className="uppercase rounded-md border border-black bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                            >
                                            Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>


          </div>
        </div>
            
        
       
      </div>);

}
export default SalesVerification;