import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import TaxReturnList from '../../layout/taxreturn/taxreturnlist.layout'
import TaxReturnCreate from '../../layout/taxreturn/taxreturncreate.layout'
import TaxReturnUpdate from '../../layout/taxreturn/taxreturnupdate.layout'
import { UserContext } from '../../contexts/user.context'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { formatAmount } from '../../utils/ValidationField';
const ProfilePage =() =>{

  
  const [isCompletesubmit, setCompletesubmit] = useState(false);


    const [sidebarOpen, setSidebarOpen] = useState(false)

    const { company_id } = useContext(UserContext);
    const { user_id } = useContext(UserContext);
 
    const [CompanyProfile, setCompanyProfile] = useState([]);
    const [Taxtypes, setTaxtypes] = useState([]);

    const [typeTax, setTypeTax] = useState('N/A'); 

    const [VTRadion,setVTRadion] = useState([
      { id: 'vt', title: 'vat' ,isChecked:false},
      { id: 'nvt', title: 'non vat' ,isChecked:true},
    ]);
      const getCheckedId = () => {
        const checkedItem = VTRadion.find(item => item.isChecked);
        return checkedItem ? checkedItem.id : null;
    };
      const handleRadioChange = (id) => {
        console.log(id);
        setVTRadion(prevState =>
            prevState.map(item =>
                item.id === id ? { ...item, isChecked: true } : { ...item, isChecked: false }
            )
        );
    };

    const handleChange = (event) => {
      setTypeTax(event.target.value);
    if(event.target.value=='PT'){
     
      updateTaxTypes(2, 3);
   
    }
    if(event.target.value=='VT'){
     
      updateTaxTypes(3, 2);
    }
    if(event.target.value=='N/A'){
      updateTaxTypesFalseAll(2,3);
    }
    };
   
    const updateTaxTypes = (idToTrue, idToFalse) => {
      const updatedTaxTypes = Taxtypes.map((tax) => {
        if (tax.id === idToTrue) {
          return { ...tax, checked: true };
        }
        if (tax.id === idToFalse) {
          return { ...tax, checked: false };
        }
        return tax;
      });
      console.log(updatedTaxTypes);
      setTaxtypes(updatedTaxTypes);


    };
    const updateTaxTypesFalseAll = (idToTrue, idToFalse) => {
      const updatedTaxTypes = Taxtypes.map((tax) => {
        if (tax.id === idToTrue) {
          return { ...tax, checked: false };
        }
        if (tax.id === idToFalse) {
          return { ...tax, checked: false };
        }
        return tax;
      });
      console.log(updatedTaxTypes);
      setTaxtypes(updatedTaxTypes);


    };
    useEffect( () => {
   
      LoadProfileInfo();

 
    }, []); 

    
    const LoadProfileInfo = async () => {


      //  console.log(company_id);
        const postData = {
          "company_id": company_id,
  
        };
       
      //  console.log(postData);
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/get/company/profile', requestOptions)
        .then( async response => 
          {
           //  console.log(response);
            // Check the status code
            if (response.status === 422) {

               
            }else{
              const data = await response.json();

              // console.log(isTaxTypeCheckedById(2,data.tax_types));
              // console.log(isTaxTypeCheckedById(3,data.tax_types));
              console.log(data.tax_types);
               

              let PT= data.tax_types.find(tax => tax.id === 2)
              if(PT.checked){
                setTypeTax('PT');
              }
              let VT= data.tax_types.find(tax => tax.id === 3)
              if(VT.checked){
                setTypeTax('VT');
              }

         
              setCompanyProfile(data);

           
              setTaxtypes(data.tax_types);
            
        

              //setSelectedSelectedVAT((data.is_vat==1)? 'vt' :'nvt');
              handleRadioChange((data.is_vat==1)? 'vt' :'nvt');
              console.log(VTRadion);

            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
        //   setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        // setLoad(true);
    }
    

    const handleCheckboxChangeTax = (id) => {
      

      const  updatedTaxTypes = Taxtypes.map((tax) =>
       
        tax.id === id ? { ...tax, checked: !tax.checked } : tax
      );
     
      setTaxtypes(updatedTaxTypes);
      };

      
      const SaveTaxType = () => {

   
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Do you want to save",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            reverseButtons: true
          }).then(async (result) => {
            if (result.isConfirmed) {

              setCompletesubmit(true);
      
              



                 //  console.log(postData);
                const postData = {
                  "company_id": company_id,
                  "user_id": user_id,
                  "tax_types": Taxtypes,
                  "is_vat":(getCheckedId() =='vt')? 1 : 0,
                };
              
                const requestOptions = {
                  method: 'POST',
                  headers: 
                  { 'Content-Type': 'application/json',
                  'Accept': 'application/json', 
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                  body: JSON.stringify(postData)
                };
            
            await fetch(process.env.REACT_APP_API_URL+'api/update/company/profile', requestOptions)
                .then( async response => 
                  {
                  console.log(response);
                    // Check the status code
                    if (response.status === 422) {
                      setCompletesubmit(false);
                      
                    }else{
                      const data = await response.json();
                      console.log(data);
                      
                      setCompanyProfile(data);
                 
                      
                     localStorage.setItem('Tax_types', JSON.stringify(Taxtypes));
                     localStorage.setItem('is_vat', ((getCheckedId() =='vt')? 1 : 0));
                     setCompletesubmit(false);

                    handleRadioChange('vt');

                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'update successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })  
                    window.location.reload();
                    }
                  }
                  
                )
                .then(data => {
                  // Handle the response
              
              
                //   setLoad(true);
                })
                .catch(error => {
                  // Handle any errors
              
                });
      
            
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
      
            }
          })
      
      
      
      };
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4 ">
            <div className="flex h-16 shrink-0 items-center   mt-10">
              <img
                className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Profile'}  />
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>


         
          <main className={isCompletesubmit ? "opacity-20 py-2 " : "py-2 "}>
            <div className="px-4 sm:px-6 lg:px-8 max-w-6xl mt-10">
              <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                <div>
                <div className="px-4 sm:px-0 ">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Company Information</h3>
      
                </div>
            
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Trade Name</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">{CompanyProfile.trade_name}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Register Address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">{CompanyProfile.reg_address}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Line Business</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">{CompanyProfile.line_business}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Owner Name</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 capitalize">{CompanyProfile.owner_name}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 ">{CompanyProfile.email}</dd>
                    </div>
                    
                    </dl>
                </div>
         
                <div className="sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900 opacity-0">
                        VAT
                    </label>
                    <div className="mt-2">
                        <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {VTRadion.map((RadioItem) => (
                              <div key={RadioItem.id} className="flex items-center">
                                  <input
                                      id={RadioItem.id}
                                      name="notification-method"
                                      type="radio"
                                      value={RadioItem.id}
                                      checked={RadioItem.isChecked}
                                      onChange={() => handleRadioChange(RadioItem.id)}
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                  <label htmlFor={RadioItem.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      {RadioItem.title}
                                  </label>
                              </div>
                          ))}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                <select  
                className=" p-2.5 block w-full
                rounded-md border-0 py-1.5 text-gray-900 
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2
                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
           
                name="TAXTYPE"
                value={typeTax} // Bind the value to state
                onChange={handleChange} // Handle change events
                >
                <option value="PT">Percentage Tax (PT)</option>
                <option value="VT">Value added tax (VT)</option>
                <option value="N/A">N/A</option>
                </select>
                </div>
             
                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                      {Taxtypes.map((prev) => (
                      
                      
                      (prev.id !=2 && prev.id !=3)?
                        
                          <li key={prev.id} className="col-span-1 divide-y divide-gray-200 rounded-lg ">
                         
                          <div className="flex w-full items-center justify-between space-x-6 p-6 bg-white shadow">
                       
                              <div className="flex-1 truncate">
                
                              <p className="mt-1 truncate text-sm text-gray-500 font-bold capitalize">{prev.description}</p>
                              </div>
                             
                              <input
                                id={'option' + prev.id}
                                aria-describedby={'option' + prev.id}
                                name={'option' + prev.id}
                                type="checkbox"
                                checked={prev.checked}
                                onChange={() => handleCheckboxChangeTax(prev.id)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                          </div>
                          
                          </li>
                          :''
                      ))}
                    </ul>

                </div>
                     
                </div>
                <div className="flex items-center justify-end border-t border-gray-900/10 py-4 uppercase">
                    <button
                    type="submit"
                    onClick={() => SaveTaxType()}
                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Save
                    </button>
                </div>

             </div>
            </div>
          </main>
        </div>
      </div>


      
  </>);

}
export default ProfilePage;