import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import UserAdd from '../../layout/user/useradd.layout'
import UserList from '../../layout/user/userlist.layout'
import { UserContext } from '../../contexts/user.context'
import Swal from 'sweetalert2';
import UserEdit from '../../layout/user/useredit.layout'
const UserPage =() =>{

  const { company_id } = useContext(UserContext);


  const [Pagination, setPagination]= useState([]);


  useEffect(() => {
    setCompanyid(company_id);
    LoadPrivelege();

    console.log(selectedPrivelege);
  }, []); 

  const [Companyid, setCompanyid] = useState('');

  
  const [selectedPrivelege, setSelectedPrivelege] = useState([]);


  const [checkboxes, setCheckboxes] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
    option7: false,
    option8: false,
  });

  
  const handleCheckboxClick = (value) => {
    // Update the state of checkboxes
    setCheckboxes(prevState => ({
        ...prevState,
        ['option'+value]: !prevState['option'+value] // Toggle the checkbox value
    }));


    console.log(value);
    setSelectedPrivelege(prevPriveleges => {
      if (prevPriveleges.includes(value)) {
        // If value exists, remove it
        return prevPriveleges.filter(privelege => privelege !== value);
      } else {
        // If value doesn't exist, add it
        return [...prevPriveleges, value];
      }

     
    });

    console.log(selectedPrivelege);
};

  const [PrivelegeItem, setPrivelegeItem] = useState([]);

  const defaultFormFields = {
    first_name: '',
    middle_name:'',
    last_name:'',
    suffix:'',
    position: '',
    email: '',
    mobile: '+639',
    password: '',
    confirmpassword: '',
    landline:''
  };
  const [formFields,setFormFields ] = useState(defaultFormFields);
  const { first_name,middle_name,last_name,suffix, email,mobile,password,confirmpassword,landline,position} = formFields;



  const handleChange = (event) => {
    const { name, value } = event.target;
 
    if (name==="mobile"){
    
      setFormFields({ ...formFields, [name]: formatMobileNumber(value) });
      return; // Exit the function early to prevent setting the value again
    }

    if(name==="position"){


      if(value==="Manager"){
        setCheckboxes({
          option1: true,
          option2: true,
          option3: true,
          option4: true,
          option5: true,
          option6: true,
          option7: true,
          option8: true,
          option9: true,
          option10: true,
          option11: true,
          option12: true,
      });

      setSelectedPrivelege([1,2,3,4,5,6,7,8,9,10,11,12]);
      }
      if(value==="ACT-Clerk"){
        setCheckboxes({
          option1: false,
          option2: false,
          option3: false,
          option4: false,
          option5: true,
          option6: true,
          option7: true,
          option8: true,
          option9: true,
          option10: true,
          option11: false,
          option12: false,
      });

      setSelectedPrivelege([5,6,7,8,9,10]);
      }
      if(value==="Encoder"){
        setCheckboxes({
          option1: true,
          option2: false,
          option3: true,
          option4: false,
          option5: false,
          option6: false,
          option7: false,
          option8: false,
          option9: false,
          option10: false,
          option11: false,
          option12: false,
      });
      setSelectedPrivelege([1,3]);
      }
      if(value==="Bookkeeper"){
        setCheckboxes({
          option1: true,
          option2: true,
          option3: true,
          option4: true,
          option5: true,
          option6: true,
          option7: true,
          option8: true,
          option9: true,
          option10: true,
          option11: true,
          option12: true,
      });

      setSelectedPrivelege([1,2,3,4,5,6,7,8,9,10,11,12]);
      }
   
     
    }
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(password !==confirmpassword){
      setErrorShow(true);
      setErrorPasswordText('Invalid confirm password is not the same');
      return;
    }else{
      setErrorShow(false);
    }
    // if(checkPasswordStrength(password)!=='Strong'){
    //   setErrorShow(true);
    //   setErrorPasswordText('Invalid your password was not strong');
    //   return;
    // }else{
    //   setErrorShow(false);
    // }
    setCompletesubmit(true);
    const postData = {
      "first_name": first_name,
      "middle_name": middle_name,
      "last_name": last_name,
      "suffix": suffix,
      "role": position,
      "email": email,
      "mobile": mobile,
      "password":password,
      "landline":landline,
      "company_id":Companyid,
      "privelege":selectedPrivelege,
      "password":password
    };

 
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/register/user', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
          setErrorShow(true);
          setErrorPasswordText('Email already exist');
          setCompletesubmit(false);
        }else{
          const data = await response.json();
      
          setCompletesubmit(false);
          setshowListUser(true);
          setshowAddUser(false);
          setPagination(data.pagination)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Create New User Successfully',
            showConfirmButton: false,
            timer: 1500
        })  
        // //  navigate('/');
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
   
  }; 


  
  const LoadPrivelege = async () => {



  
    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
    };

 await fetch(process.env.REACT_APP_API_URL+'api/setting/privilege/list', requestOptions)
    .then( async response => 
      {
         console.log(response);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

         // const data = await response.json();
       
          // handleData(data.message);
          //  setNodata(false);
           
        }else{
          const data = await response.json();
          console.log(data.data);
       
          setPrivelegeItem(data.data);
       
          
        }
      }
      
    )
    .then(data => {
      // Handle the response
   
  
      // setLoad(true);
    })
    .catch(error => {
      // Handle any errors
   
    });
    // setLoad(true);
} 


  const handleSubmitUpdate = async (event) => {
    event.preventDefault();

    setCompletesubmit(true);
    const postData = {
      "user_id": Userid,
      "first_name": first_name,
      "middle_name": middle_name,
      "last_name": last_name,
      "suffix": suffix,
      "role": position,
      "mobile": mobile,
      "privelege":selectedPrivelege
    };

    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/user/update', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
          setErrorShow(true);
  
          setCompletesubmit(false);
        }else{
          const data = await response.json();


          console.log(data)

          setCompletesubmit(false);
          setshowListUser(true);
          setshowAddUser(false);
          setshowEditUser(false);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Update User Successfully',
            showConfirmButton: false,
            timer: 1500
        })  
        //  navigate('/');
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
   
  }; 
  const [sidebarOpen, setSidebarOpen] = useState(false)


  const [isErrorShow, setErrorShow] = useState(false);
  const [isErrorPasswordText, setErrorPasswordText] = useState('');
  const [isCompletesubmit, setCompletesubmit] = useState(false);


  const [showListUser, setshowListUser] = useState(true);
  const [showAddUser, setshowAddUser] = useState(false);
  const [showEditUser, setshowEditUser] = useState(false);

  const [Userid, setUserid] = useState(false);

  const GotoAddUser = () => {
    setshowListUser(false);
    setshowAddUser(true);
  }


  const [Email, setEmail] = useState('');




    
  const GotoEditUser = (email,firstname,middlename,lastname,suffix,mobile,role,user_id,privelege) => {

    console.log(user_id);
    setEmail(email);
    setUserid(user_id);
    setFormFields({
      ...defaultFormFields,
      first_name: firstname,
      middle_name:middlename,
      last_name:lastname,
      suffix: suffix,
      mobile: mobile,
      position: role
      // Set other initial values as needed
    });


    
    setCheckboxes({
      option1: privelege.includes(1)? true: false,
      option2: privelege.includes(2)? true: false,
      option3: privelege.includes(3)? true: false,
      option4: privelege.includes(4)? true: false,
      option5: privelege.includes(5)? true: false,
      option6: privelege.includes(6)? true: false,
      option7: privelege.includes(7)? true: false,
      option8: privelege.includes(8)? true: false,
      option9: privelege.includes(9)? true: false,
      option10: privelege.includes(10)? true: false,
      option11: privelege.includes(11)? true: false,
      option12: privelege.includes(12)? true: false,
  });

    setSelectedPrivelege([1,2,3,4,5,6,7,8,9,10,11,12]);

    console.log(selectedPrivelege);
    setshowListUser(false);
    setshowEditUser(true);
  }


  function checkPasswordStrength(password) {
    // Define regex patterns for different character types
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/;
  
    // Check length
    if (password.length < 8) {
      return 'Weak (password should be at least 8 characters)';
    }
  
    // Check for different character types
    const hasLowercase = lowercaseRegex.test(password);
    const hasUppercase = uppercaseRegex.test(password);
    const hasDigit = digitRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);
  
    // Evaluate strength based on character types
    if (hasLowercase && hasUppercase && hasDigit && hasSpecialChar) {
      return 'Strong';
    }
  
    if ((hasLowercase && hasUppercase && hasDigit) || (hasLowercase && hasUppercase && hasSpecialChar) || (hasLowercase && hasDigit && hasSpecialChar) || (hasUppercase && hasDigit && hasSpecialChar)) {
      return 'Medium';
    }
  
    return 'Weak';
  }


  const formatMobileNumber =(value)=>{

    let numericValue = value.replace(/\D/g, '');

    // Check if the input starts with the country code +63
    if (numericValue.startsWith('639')) {
      // If it does, keep it as it is
      numericValue = `+639 ${numericValue.substring(3)}`;
    } else if (numericValue.length >= 1) {
      // If not, add the country code +63
      numericValue = `+639 ${numericValue}`;
    } else {
      // If the input is empty, clear the input value
      numericValue = '';
    }

    return numericValue;
  }

  return (
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center  mt-10">
              <img
             className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'}
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'User'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
              <div className="space-y-10 divide-y divide-gray-900/10">
           
                    {showListUser? <UserList GotoAddUser={GotoAddUser} GotoEditUser={GotoEditUser}/>:''}
                    {showAddUser?  <UserAdd handleSubmit={handleSubmit} isCompletesubmit={isCompletesubmit} isErrorShow={isErrorShow} isErrorPasswordText={isErrorPasswordText} first_name={first_name} middle_name={middle_name} last_name={last_name} suffix={suffix} email={email} mobile={mobile} landline={landline} password={password} confirmpassword={confirmpassword} PrivelegeItem={PrivelegeItem} handleChange={handleChange} handleCheckboxClick={handleCheckboxClick} checkboxes={checkboxes}/>: ''}
                    {showEditUser? <UserEdit handleSubmitUpdate={handleSubmitUpdate} handleChange={handleChange} formFields={formFields} Email={Email} PrivelegeItem={PrivelegeItem}  handleCheckboxClick={handleCheckboxClick} checkboxes={checkboxes} /> :''}
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}
export default UserPage;