

import { UserContext } from '../../contexts/user.context'
import { useEffect,useState,useContext } from 'react'
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet,Link   } from '@react-pdf/renderer';
import { formatAmountDisplay } from '../../utils/ValidationField';
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const RptSalesSummary =(props) =>{

    const [isLoad, setLoad] = useState(true);

    const [pendinglist, setPendingList] = useState([]);
    const [Pagination, setPagination]= useState([]);
    const { company_id } = useContext(UserContext);
    const [Searchdata, setInputValue] = useState('');
    const [allClass, setClass] = useState([]);

    const [withsummary, setWithSummary] = useState(false);
    const customFileName = 'Sales_Report_Custom_Name.pdf'; // Customize the file name here

    const handleToggle  = (event) => {
      setWithSummary(event.target.checked);
      console.log(withsummary);
    };

    const [isCompletesubmit, setCompletesubmit] = useState(false);

    const { Tax_types } = useContext(UserContext);

    
    const CheckTax_VAT = ()=>{
      const item =JSON.parse(Tax_types).find(item => item.id === 3);
      return item.checked;
     
    }
    // const handlePageChangeParent = (newPage) => {
    //   // LoadPendingList(newPage);
    //   setPendingList([]);
    //   ReportListExpense(newPage)

    //   console.log(newPage);
    // };


    useEffect( () => {

      LoadClassList();

  }, []); 


  const styles = {
    container: {
        padding: 10,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: 'gray',
        paddingBottom: 0,
    },
    label: {
        width: 100,
        fontWeight: 'bold',
        fontSize: 14,
    },
    value: {
        flex: 1,
        fontSize: 14,
    },
    imageContainer: {
        marginBottom: 8,
        alignItems: 'center',
    },
    rowExpense: {
        flexDirection: 'row',
    },
    cell: {
        width: '20%',
        padding: 2,
        border: '1px solid black',
        fontSize: 8, // Add font size here
        textTransform: 'capitalize', // Capitalize text
        wordWrap: 'break-word', // Add wordWrap property here
    },
};
  
  

     const [showPDF, setShowPDF] = useState(false);

    // const generatePDF = () => {
    //   setShowPDF(true);
    // };
    const [isNodata, setNodata] = useState(false);

    

    const calculateTotal = (listdata,propertyName) => {
      let total = 0;
      listdata.forEach(item => {
        total += parseFloat(item[propertyName]) || 0;
      });

      
      return (total !=null)? formatAmountDisplay(total) : '-';
    };

    const calculateTotalWithoutFormat = (listdata,propertyName) => {
      let total = 0;
      listdata.forEach(item => {
        total += parseFloat(item[propertyName]) || 0;
      });

      
      return total;
    };
  
    const defaultFormFields = {
        fromdate: '',
        todate:'',
        classification:''
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { fromdate,todate,classification} = formFields;
    

    const handleChange = (event) => {
        const { name, value } = event.target;
     
        
        setFormFields({ ...formFields, [name]: value });
      };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        ReportListExpense();
        setShowPDF(true);
       
      };

    const SearchExpense=() =>{
       
        ReportListExpense();
        setShowPDF(true);
    }
    const ReportListExpense = async (page = 1) => {

      setPendingList([]); 

      setCompletesubmit(true);

     
        const postData = {
          "company_id": company_id,
          "startdate":fromdate,
          "enddate":todate,
          "classification":classification,
          "page":page
        };


       
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/report/sales/summary', requestOptions)
        .then( async response => 
          {
                
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               setCompletesubmit(false);
            }else{
              const data = await response.json();
              console.log(data.data);
               setPendingList(data.data); 
              setPagination(data.pagination);
               props.setshowSummaryCreate(false);
               props.setshowListPending(true);
               setCompletesubmit(false);
              
              
            }


            console.log(response);
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);

        setCompletesubmit(false);
    } 



    const handleExport = async (page = 1) => {

    //  setPendingList([]); 

     // setCompletesubmit(true);

     
        const postData = {
          "company_id": company_id,
          "startdate":fromdate,
          "enddate":todate,
          "classification":classification,
          "page":page
        };


       
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/report/sales/summary', requestOptions)
        .then( async response => 
          {
                
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               setCompletesubmit(false);
            }else{
              const data = await response.json();
              console.log(data.data);
              


              const dataExcel = [];



              let VatSales=0;
              let VatGOV=0;
              let VatEXEMPT=0;
              let VATZeroRated=0;
              let VATAmount=0;
              let TotalAmountDUe=0;

              data.data.forEach(item => {
            
                VatSales+=parseFloat(parseFloat(item.vat_sales));
                VatGOV+=parseFloat(parseFloat(item.vat_gov));;
                VatEXEMPT+=parseFloat(parseFloat(item.vat_exempt));;
                VATZeroRated+=parseFloat(parseFloat(item.vat_zero_rated));;
                VATAmount+=parseFloat(parseFloat(item.vat_amount));;
                TotalAmountDUe+=parseFloat(parseFloat(item.total_amount_due));;


                  const objectData = { 
                    'DATE TRANSACTION': item.date_trans,
                     'BUYER':item.seller ,
                     'TIN':item.tin ,
                     'INVOICE NO':item.ornum ,
                     'CLASSIFICATION': capitalizeFirstLetter(item.classification.toLowerCase()),
                     'VAT SALES':formatAmountDisplay(parseFloat(item.vat_sales)),
                     'VAT GOVERTMENT':formatAmountDisplay(parseFloat(item.vat_gov)),
                     'VAT EXEMPT':formatAmountDisplay(parseFloat(item.vat_exempt)),
                     'VAT ZERO RATED':formatAmountDisplay(parseFloat(item.vat_zero_rated)),
                     'VAT AMOUNT':formatAmountDisplay(parseFloat(item.vat_amount)),
                     'TOTAL AMOUNT DUE':formatAmountDisplay(parseFloat(item.total_amount_due)),
                     'REMARKS':item.remarks,
                   };
                  dataExcel.push(objectData);
           

                
               

               
              });
            
              const objectData = { 
                'DATE TRANSACTION':'GRAND TOTAL',
                 'BUYER':'',
                 'TIN':'',
                 'INVOICE NO':'',
                 'CLASSIFICATION': '',
                 'VAT SALES':formatAmountDisplay(VatSales),
                 'VAT GOVERTMENT':formatAmountDisplay(VatGOV),
                 'VAT EXEMPT':formatAmountDisplay(VatEXEMPT),
                 'VAT ZERO RATED':formatAmountDisplay(VATZeroRated),
                 'VAT AMOUNT':formatAmountDisplay(VATAmount),
                 'TOTAL AMOUNT DUE':formatAmountDisplay(TotalAmountDUe),
                 'REMARKS':'',
               };
              dataExcel.push(objectData);

              // dataExcel.push(objectData);

              const workbook = XLSX.utils.book_new();
              const worksheet = XLSX.utils.json_to_sheet(dataExcel);

              // Append the worksheet to the workbook
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

              // Convert the workbook to binary array
              const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

              // Use file-saver to save the file
              const now = new Date();
              const formattedDate = now.toISOString().replace(/T/, '_').replace(/\..+/, ''); // e.g., "2024-08-25_14:30:00"
              const filename = `SalesReport_${formattedDate}.xlsx`;
          
              // Use file-saver to save the file
              const blob = new Blob([wbout], { type: 'application/octet-stream' });
              saveAs(blob, filename);
              
            }


            console.log(response);
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);

        setCompletesubmit(false);
    } 

    function capitalizeFirstLetter(str) {
      if (str.length === 0) return str; // Return an empty string if input is empty
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    const LoadClassList = async () => {



      const postData = {
        "search":Searchdata,
        "company_id": company_id,
        "type":'sales'
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
      
            setClass(data.data); 

         
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      setLoad(true);
  } 


    const inputDateStr = "AUGUST 21, 2023";

    // Parse the input date string into a Date object
    const parsedDate = new Date(inputDateStr);


    const formattedDate = parsedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').join('-'); // Replace "/" with "-"


    // function fetchImage(imageUrl) {
    //   return axios.get('https://pentagontechno.com/api/images/'+imageUrl, { responseType: 'arraybuffer' })
    //     .then(response => {
    //       const base64Image = btoa(new Uint8Array(response.data).reduce((data, byte) => {
    //         return data + String.fromCharCode(byte);
    //       }, ''));
    //       return `data:image/jpeg;base64,${base64Image}`;
    //     })
    //     .catch(error => {
    //       console.error('Error fetching image:', error);
    //       return null; // You can handle the error as needed
    //     });
    // }
    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-lg text-gray-700 font-bold">Report</h1>
            <p className="mt-2 text-lg text-gray-700 font-bold">
               Sales Report
            </p>
          </div>

        </div>
       
        <form >
            <div class="max-w-6xl bg-gray-50 p-6 rounded-lg shadow-md mt-10 mx-auto">
                <div class="grid grid-cols-8 gap-4">
                  <div class="col-span-2">
                    <div class="flex items-center">
                      <label for="fromdate" class="text-sm font-medium text-gray-700 mr-4">From Date</label>
                          <input type="date"
                          id="fromdate" 
                          name="fromdate"
                          class="w-full p-2 border rounded-md
                          border-gray-300 shadow-sm
                          focus:border-indigo-300 focus:ring
                          focus:ring-indigo-200 focus:ring-opacity-50"
                          value={fromdate}
                          required
                          onChange={handleChange}
                          />
                      </div>
                  </div> 
                  <div className="col-span-2">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">To Date</label>
                          <input
                            type="date"
                            id="todate" 
                            name="todate" 
                            className="w-full p-2 border 
                            rounded-md border-gray-300
                            shadow-sm focus:border-indigo-300 
                            focus:ring focus:ring-indigo-200
                              focus:ring-opacity-50"
                              value={todate}
                              required
                              onChange={handleChange}
                          />
                      
                    </div>   
                  </div> 
                  <div className="col-span-3">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">Classification</label>
                      <select  
                            className=" p-2 w-full border 
                            rounded-md border-gray-300
                            shadow-sm focus:border-indigo-300 
                            focus:ring focus:ring-indigo-200
                              focus:ring-opacity-50"
                            id="classification"
                            name="classification"
                            value={classification}
                            onChange={handleChange}
                            required>
                                <option>ALL</option>
                                {allClass.map((Classification) => (
                                    <option key={Classification.id} value={Classification.class}>
                                    {Classification.class}
                                    </option>
                                ))}
                            </select>
                      
                    </div>   
                  </div> 
                  {/* <div className="col-span-2">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">With Summary</label>
                      <input
                            type="checkbox"
                            id="withsummary" 
                            name="withsummary" 
                            className="w-full p-2 border 
                              focus:ring-opacity-50"
                              required
                              checked={withsummary}
                              onChange={handleToggle}
                          />
                      
                    </div>   
                  </div>  */}
                </div>
                <div class="mt-4 flex items-center justify-center">
                <button 
                        type="button"  onClick={() =>SearchExpense()} 
                        class="ml-2
                        bg-indigo-500
                        text-white px-4
                        py-2 rounded-md
                        hover:bg-indigo-600
                        focus:outline-none focus:ring
                        focus:ring-indigo-200
                      focus:ring-opacity-50 uppercase">Submit</button>
                <button
                type="button"  onClick={() =>handleExport()} 
                className="ml-2
                bg-white
                text-black"
                >


                <svg  className="-mr-0.5 h-12 w-12" viewBox="-4 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M5.112.006c-2.802 0-5.073 2.273-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.902-20.31h-31.946z" fill-rule="evenodd" clip-rule="evenodd" fill="#45B058"/><path d="M19.429 53.938c-.216 0-.415-.09-.54-.27l-3.728-4.97-3.745 4.97c-.126.18-.324.27-.54.27-.396 0-.72-.306-.72-.72 0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826c-.09-.126-.145-.27-.145-.414 0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646c.127-.18.324-.27.541-.27.378 0 .738.306.738.72 0 .144-.036.288-.127.414l-3.619 4.808 3.891 5.149c.09.126.125.27.125.414 0 .396-.324.738-.719.738zm9.989-.126h-5.455c-.595 0-1.081-.486-1.081-1.08v-10.317c0-.396.324-.72.774-.72.396 0 .721.324.721.72v10.065h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278.162.144.253.342.253.54 0 .36-.307.72-.703.72-.145 0-.307-.054-.432-.162-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z" fill="#ffffff"/><path d="M55.953 20.352v1h-12.801s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z" fill-rule="evenodd" clip-rule="evenodd" fill="#349C42"/><path d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801l-18.905-20.352z" opacity=".5" fill-rule="evenodd" clip-rule="evenodd" fill="#ffffff"/></svg>
                </button>
                     
                </div>
            </div>

        </form>
        { isCompletesubmit?   <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">
          <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span class="sr-only">Loading...</span>
          </div> 
       </div>   : ''
      }
      <div className="max-w-screen-lg mx-auto mt-10 h-screen">
          
      <PDFViewer className="w-full h-full" >
      <Document>
      <Page size={{ width: 1000, height: 1000 }} style={styles.container}>
          <View >


          <View  style={styles.row}>
            <Text style={styles.cell}>Date Transaction</Text>
            <Text style={styles.cell}>Buyer</Text>
            <Text style={styles.cell}>TIN</Text>
            <Text style={styles.cell}>Invoice No</Text>
            <Text style={styles.cell}>Clasification</Text>

            {CheckTax_VAT()?
            <>
        
            <Text style={styles.cell}>Vat Sales</Text>
      
            <Text style={styles.cell}>Vat Goverment</Text>
        
      
            <Text style={styles.cell}>Vat Exempt</Text>
 
       
            <Text style={styles.cell}>Vat Zero Rated</Text> 
         
            <Text style={styles.cell}>Vat Amount</Text>
          
           
            <Text style={styles.cell}>Total Amount Due</Text>
     
            </>

            :''}
             <Text style={styles.cell}>Remarks</Text>
            {/* <Text style={styles.cell}>Pic</Text> */}
            </View>
          {pendinglist.map((Rpt) => ( 
            <>
          
        
               
               <View  style={styles.row}>
              
              <Text style={styles.cell}>{Rpt.date_trans}</Text>
              <Text style={styles.cell}>{Rpt.seller}</Text>
              <Text style={styles.cell}>{Rpt.tin}</Text>
              <Text style={styles.cell}>{Rpt.ornum}</Text>
              <Text style={styles.cell}> {(Rpt.classification.toLowerCase())}</Text>
              {CheckTax_VAT()?
               <>
                 <Text style={styles.cell}>{formatAmountDisplay(Rpt.vat_sales)}</Text> 
                  <Text style={styles.cell}>{formatAmountDisplay(Rpt.vat_gov)}</Text> 
                 <Text style={styles.cell}>{formatAmountDisplay(Rpt.vat_exempt)}</Text> 
                  <Text style={styles.cell}>{formatAmountDisplay(Rpt.vat_zero_rated)}</Text> 
                
                 <Text style={styles.cell}>{formatAmountDisplay(Rpt.vat_amount)}</Text>
                
                
           
                  <Text style={styles.cell}>{formatAmountDisplay(Rpt.total_amount_due)}</Text> 
                  
           
               </>
              :''}
              <Text style={styles.cell}>{Rpt.remarks}</Text>
            </View>
         
                </>

           
        
            ))}

                      
      
          <View  style={styles.row}>
         
              <Text style={styles.cell}>Grand Total</Text>
              <Text style={styles.cell}></Text>
              <Text style={styles.cell}></Text>
              <Text style={styles.cell}></Text>
              <Text style={styles.cell}></Text>


              {CheckTax_VAT()?
              <>
      
                <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'vat_sales'))}</Text> 
              
           
              <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'vat_gov'))}</Text> 
            
              <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'vat_exempt'))}</Text>
           
         
              <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'vat_zero_rated'))}</Text>

            
              <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'vat_amount'))}</Text>

            
              <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(pendinglist,'total_amount_due'))}</Text> 
              </>
              :''}
               <Text style={styles.cell}></Text>
              
          </View>
          </View>
        </Page>
      </Document>
      </PDFViewer>


      </div>

      
       
    


       
          
      </div>);

}
export default RptSalesSummary;