import { UserContext } from '../../contexts/user.context'

import { Fragment, useRef, useState,useEffect,useContext } from 'react'


import { useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression';
import './sweetbutton.css'; 
import Swal from 'sweetalert2';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'


const TaxReturnCreate =(props) =>{

    const navigate = useNavigate();
    const { company_id } = useContext(UserContext);
    const { user_id } = useContext(UserContext);

    const { is_vat } = useContext(UserContext);


    const { Tax_types } = useContext(UserContext);

  

    const cancelButtonRef = useRef(null)

    const [OpenImage, setOpenImage] = useState(false)


    const [imageData, setImageData] = useState([]);

    const openimage=(image) => {

    //  setImgSupplemental(image);

    setOpenImage(true);

      setImageData(image);
    }


    return (
    <>
    
    <div className={props.isCompletesubmit ? "opacity-20 grid grid-cols-1 gap-x-8 gap-y-8 pt-10" : "grid grid-cols-1 gap-x-8 gap-y-8 pt-10"}>
        <div className="px-4 sm:px-0">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">NEW ENTRY <span className='text-blue-400'>({props.Taxtype})</span></h2>
           
        </div>
        <form onSubmit={props.handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 uppercase">
            <div className="px-4 py-6 sm:p-8">


      
            <div className=" md:grid lg:grid 2xl:grid gap-x-6 gap-y-8 sm:grid-cols-6 md:grid-cols-4 lg:grid-cols-12 xl:grid-cols-12 ">
           

                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="datepaid" className="block text-sm font-medium leading-6 text-gray-900">
                        Date Paid
                    </label>
                    <div className="mt-2">
                        <input
                        type="date"
                        name="date_paid"
                        id="date_paid"
                        autoComplete="date_paid"
                        value={props.date_paid}
                        required
                        onChange={props.handleChange}
                     
                        className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    
                    </div>
                </div>

            
        
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Amount Paid
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="amount_paid"
                        id="amount_paid"
                        autoComplete="amount_paid"
                        value={props.amount_paid}
                        required
                        onChange={props.handleChange}
                      
                        className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Return Period
                    </label>
                    <div className="mt-2">
                        <select  
                        className="uppercase p-2.5 block w-full
                        rounded-md border-0 py-1.5 text-gray-900 
                        shadow-sm ring-1 ring-inset ring-gray-300
                        placeholder:text-gray-400 focus:ring-2
                        focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        
                        name="return_period"
                        id="return_period"
                        autoComplete="return_period"
                        value={props.return_period}
                        required
                        onChange={props.handleChange}
           
                        >
                        
                        <option value="Monthly">Monthly</option>
                        {/* <option value="Calendar Year">Calendar Year</option> */}
                        <option value="Quarterly">Quarterly</option>
                        <option value="Annually">Annually</option>
                        </select>
                                      
                     
                    </div>
                </div>
                {(props.MonthlyMode)?
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Month
                    </label>
                    <div className="mt-2">
                        <select  
                        className="uppercase p-2.5 block w-full
                        rounded-md border-0 py-1.5 text-gray-900 
                        shadow-sm ring-1 ring-inset ring-gray-300
                        placeholder:text-gray-400 focus:ring-2
                        focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        
                        name="Month"
                        id="Month"
                        autoComplete="Month"
                        value={props.Month}
                        required
                        onChange={props.handleChange}
           
                        >
                        <option value="">Select Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                 
                        </select>
                                      
                     
                    </div>
                </div>:''}

                {(props.QuarterMode)?
                 <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                 ">
                     <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                         Quarter
                     </label>
                     <div className="mt-2">
                         <select  
                         className="uppercase p-2.5 block w-full
                         rounded-md border-0 py-1.5 text-gray-900 
                         shadow-sm ring-1 ring-inset ring-gray-300
                         placeholder:text-gray-400 focus:ring-2
                         focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                         
                         name="Quarter"
                         id="Quarter"
                         autoComplete="Quarter"
                         value={props.Quarter}
                         required
                         onChange={props.handleChange}
            
                         >
                              <option value=""></option>
                         <option value="1st Quarter">1st Quarter</option>
                         <option value="2nd Quarter">2nd Quarter</option>
                         <option value="3rd Quarter">3rd Quarter</option>
                         <option value="4th Quarter">4th Quarter</option>
                     
                  
                         </select>
                                       
                      
                     </div>
                 </div>
            
               :''}
               

                {(props.MonthlyMode || props.QuarterMode || props.AnuallyMode)?

                    <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
                    ">
                        <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                            Year
                        </label>
                        <div className="mt-2">
                            <select  
                                className="uppercase p-2.5 block w-full
                                rounded-md border-0 py-1.5 text-gray-900 
                                shadow-sm ring-1 ring-inset ring-gray-300
                                placeholder:text-gray-400 focus:ring-2
                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                
                                name="YearSelected"
                                id="YearSelected"
                                autoComplete="YearSelected"
                                value={props.YearSelected}
                                required
                                onChange={props.handleChange}

                                >

                                <option value="">Select Year</option>
                                    {props.years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                    ))}
                                </select>
                        </div>
                    </div>
                    :''
                 }
          
                </div>   

                <div className="grid grid-cols-12 mt-4">
                <div className='col-span-6 border border-gray-300'>
                   <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">supplemental</h2>


                   <label for="cover-photo" class="block text-sm font-medium leading-6 text-white">Cover photo</label>
                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                          <div class="text-center">
                          <div className="mt-4">
                            <svg class="mx-auto h-12 w-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                              </svg>
                              </div>
                        
                          <div className="mt-4">
                          {props.convertDoneSupplemental?  <><svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg><span class="sr-only">Loading...</span></> :''}    
                              <label htmlFor="supplimental_fileupload" className="cursor-pointer relative rounded-md bg-indigo-500 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900">
                                  <span>Upload a file</span>
                                  <input
                                 id="supplimental_fileupload"
                                  name="supplimental_fileupload"
                                  type="file"
                                  className="sr-only"
                
                                   multiple
                                  onChange={props.SupplementalhandleFileChange}
                                  />
                              </label>
                            
                
                              <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                              </div>
                          </div>
                      </div>


                      <ul role="list" className="mx-auto grid max-w-2xl grid-cols-1 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {props.imgSupplemental.map((img, index) => (
                          <li key={index} className="relative">
                            {/* Container for image and delete button */}
                            <div className="relative">
                              {/* Image */}
                              <img onClick={() => openimage(img.imageUrl)}  className="cursor-pointer aspect-[3/2] w-full rounded-2xl object-cover duration-300 transform hover:scale-125" src={img.imageUrl} alt="" />
                              {/* Delete button */}
                              <button type="button"  onClick={() => props.removeItem(index)} className="absolute top-0 right-0 -mt-4 -mr-4 text-red-500 bg-white rounded-full p-2">
                                {/* Add a remove icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
 
                </div>
                </div> 
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                <button
                type="submit"
                className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Save
                </button>
            </div>
           
            </div>
        </form>
        </div>




        <Transition.Root show={OpenImage} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenImage}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpenImage(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
  
    </>

    
   
  )
}
export default TaxReturnCreate;