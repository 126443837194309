import { useState ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import './effectlogin.css'; // Import the CSS file containing keyframes
const Login =() =>{


  const [isErrorLogin, setErrorlogin] = useState(false);

  const [isErrorNotActivated, setErrorNotActivated] = useState(false);
  
  const [isLoading, setLoading] = useState(false);

  const defaultFormFields = {
    email: '',
    password: '',
  };
  const [formFields,setFormFields ] = useState(defaultFormFields);
  const { email, password } = formFields;


  const { Login } = useContext(UserContext);


  // const resetFormFields = () => {
  //   setFormFields(defaultFormFields);
  // };

 

  const navigate = useNavigate();



  const postData = {
    "email": email,
    "password": password,
  };

  const fetchData = async () => {
  
    setErrorNotActivated(false);
    setErrorlogin(false);
    try {
     
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(process.env.REACT_APP_API_URL+'api/login', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-TOKEN': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify(postData)
      })
      console.log(response.status);  

      if(response.status==411 || response.status=='411'){
        setErrorNotActivated(true);
        setLoading(false);
        return;
      }
      if (response.status !== 201) {
        // API call succeeded

       // const data = await response.json();
       
        setErrorlogin(true);
       
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        const data = await response.json();
      handleData(data.token,data.name,data.company_id,data.id,data.is_vat,data.privelege,data.position,data.tax_types,data.trade_name,data.tin,data.reg_address);

     
       return;
      } 
      

    } catch (error) {
      console.error(error);
      setLoading(false);
    }


  };

  

  const handleData = (jwt,name,company_id,user_id,is_vat,privelege,position,Tax_types,trade_name,tin,reg_address) => {

    console.log(is_vat);

    Login(jwt,name,company_id,user_id,is_vat,privelege,position,Tax_types,trade_name,tin,reg_address);
    navigate('/dashboard');
  };

  const handleSubmit =  (event) => {
  
    event.preventDefault();
    setLoading(true);
    fetchData();
   // setLoading(false);
   

    //resetFormFields();
  };


  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  

    return (
      <>
        <div className="flex min-h-full flex-1 uppercase">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className='flex'>
                    <div>
                    <img
                  className="w-32 mx-auto  moving-animation"
                  src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                  alt="Your Company"
                />
                    </div>
                    <div>
                    <h2 className="mt-8 text-4xl font-bold leading-9 tracking-tight text-yellow-400 text-center underline">
                      PENTABOOKS
                    </h2>
                    </div>
                </div>
               
                  
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500  text-center">
                  Not a member?{' '}
                  <a href="/step" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Register
                  </a>
                </p>
              </div>
  
              <div className="mt-10">
                <div>
                  <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          // value={email}
                          onChange={handleChange}
                          required
                          className="p-2.5 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          value={password}
                          onChange={handleChange}
                          required
                          className="p-2.5 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
          
                    <div>
       
                      {isLoading? 
                        <button disabled type="button" class="uppercase flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                          </svg>
                          Connecting...
                        </button>
                      
                      :
                      <button
                      type="submit"
                      className="uppercase flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>}

                    </div>
                     
                  </form>
                  {isErrorLogin?
                   <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>Invalid Username and password</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                  :''
                  }  
                  {isErrorNotActivated?
                  <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>Please Call Administrator to activate your account</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                       :''
                      }  
                </div>
             
    
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
          <div class="flex items-center justify-center h-full relative ">
              <img
                className="w-full h-full object-cover"
                  src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                  alt="Example Image"
                />
              <div className="overlay absolute inset-0  px-24 pt-10 items-center justify-center flex">
                <div className='bg-black flex bg-opacity-50 gap-4'>
                <div className='w-6/12 border-white border-2 px-2'>
                    <span className="text-white text-2xl font-bold underline">Pentabooks</span>
                    <p className='text-white px-2'>
                    Our company is dedicated to transforming financial management through innovative digital bookkeeping solutions. We are committed to driving the digitalization of local businesses, enabling them to achieve seamless efficiency and growth.
                    </p>
                    </div>
                    <div className='w-6/12 border-white border-2'>
                       <span className="text-white text-2xl font-bold underline px-2">Our Mission</span>
                       <p className='text-white px-2'>
                       Transforming financial management with innovative digital bookkeeping for seamless digitalization, efficiency, and growth.
                       </p>
                    </div>
                </div>
             
              </div>
             </div>
          </div>
        </div>
      </>
     
    )
  }
  export default Login;