
import { Fragment, useRef, useState, useEffect,useContext} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
import React from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';


const SummaryInfo =(props) =>{

  
    const [open, setOpen] = useState(true)
        
    const cancelButtonRef = useRef(null)
    const [isLoad, setLoad] = useState(true);

    const [isNodata, setNodata] = useState(false);
 
    const [ListSummary, setListSummary] = useState([]);
    useEffect( () => {
      setLoad(false);

      LoadSummary();
     
  }, []); 
  
  const { company_id } = useContext(UserContext);
  
    const LoadSummary = async () => {

        const postData = {
          "company_id": company_id,
           "expense_id":props.expense_id,
        //   "status":'Pending'
        };
    
        console.log(postData);
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/summary/list', requestOptions)
        .then( async response => 
          {
              console.log(response.status);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
              const data = await response.json();

              // handleData(data.message);
               setNodata(false);
               
            }else{
              const data = await response.json();
          
              setListSummary(data); 
               props.setshowSummaryCreate(false);
               props.setshowListPending(true);
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
          console.log(data);
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 

    
    const [imageData, setImageData] = useState(null);
      
    useEffect(() => {
        setImageData(props.imageattach);

    }, []);
  
    
    const removeTime = (dateTimeString) => {
      const dateTime = new Date(dateTimeString);
      const dateOnly = dateTime.toISOString().split('T')[0];
      return dateOnly;
    };
    return (
        <div>
        <div className="px-4 sm:px-0 mt-4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Summary</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
        </div>

        <div class="grid grid-cols-2 ">
                <div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900">DATE transaction</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.DateEncoded}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">ENCODER</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.Encoder}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className=" border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">SELLER</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.Seller}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className=" border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Total Amount</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">₱{props.TotalAmount.toLocaleString()}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">CLASSIFICATION</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.Classification}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                
                <div>
                    <div className="border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">tin</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.Tin}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className=" border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Vat Sales</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(props.Vat_Sales !=null)?  '₱'+props.Vat_Sales.toLocaleString() :null}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className=" border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Vat_Amount</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(props.Vat_Amount !=null)?  '₱'+props.Vat_Amount.toLocaleString() :null}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Vat Zero Rated</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(props.Vat_Zero_Rated !=null)? '₱'+props.Vat_Zero_Rated.toLocaleString() :null}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                
                <div>
                    <div className="border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Vat Exempt</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(props.Vat_Zero_Rated !=null)?  '₱'+props.Vat_Zero_Rated.toLocaleString() :null}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-4">
                    <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Show Proof
                    </button>
                </div>

                <div className='col-span-full '>
                <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>
                        <div className="">
                       
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300 uppercase">
                                <thead className="bg-gray-50">
                                    <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                        Date
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        OR #
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Particular / item description
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Quantity
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Price
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Total
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {props.listItem.map((item, index) => (
                                            <tr key={index}>
                                                <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                {item.date_trans}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.ornum}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.particular}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.qty}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{item.price.toLocaleString()}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{item.total.toLocaleString()}</td>
                                              
                                            </tr>
                                            ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                        <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>

                </div>
             
            <div className= {isLoad? 'mt-2 flow-root uppercase ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>

                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Quantity
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Particular
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Classification
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Amount
                      </th>
                   
                   
                  
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {ListSummary.map((list) => (
                      <tr key={list.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {removeTime(list.created_at)} 
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{list.quantity}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{list.particular}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{list.classification}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{list.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
   

              </div>
            </div>
          </div>
        </div>       
            
        <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                    </Transition.Root>
      </div>
      )
}
export default SummaryInfo;