const StepFormTwo = (props) => {
   
    return (
        <>
         <div className="space-y-12 uppercase">
            

                    <div className="border-b border-gray-900/10 pb-12 mt-10">

                 
                      
                      <div className="mt-10 grid grid-cols-12 gap-x-6 gap-y-8 sm:grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
                         <p className="mt-2 text-left">   CREATE A STRONG PASSWORD: USE A COMBINATION OF UPPERCASE AND LOWERCASE LETTERS, NUMBERS, SPECIAL CHARACTERS AND ATLEAST 8 CHARACTER. AVOID USING EASILY GUESSABLE INFORMATION LIKE YOUR NAME OR BIRTHDATE.</p>
                      </div>
                      <div className="col-span-9">
                        <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 2xl:grid-cols-12 gap-2">
                            <div className="sm:col-span-4">
                              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  autoComplete="email"
                                  required
                                  value={props.email}
                                  onChange={props.handleChange}
                                  className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-4">
                              <label htmlFor="Password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2 relative">
                                    <input type={props.isPasswordVisible ? 'text' : 'password'}
                                    name="password" 
                                    id="password" 
                                    required
                                    value={props.password}
                                    onChange={props.handleChange}
                                    autoComplete="currentpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => props.togglePassword('password')} style={{ display: props.showPasswordButton ? 'block' : 'none' }}>
                                        Show
                                    </button>
                              </div>

                                
                         
                            </div>
                            <div className="sm:col-span-4">
                              <label htmlFor="Confirm Password" className="block text-sm font-medium leading-6 text-gray-900">
                                Confirm Password  <span style={{ color: 'red' }}>*</span>
                              </label>
                              <div className="mt-2 relative">
                                    <input type={props.isConfirmPasswordVisible ? 'text' : 'password'}
                                    name="confirmpassword" 
                                    id="confirmpassword" 
                                    required
                                    value={props.confirmpassword}
                                    onChange={props.handleChange}
                                    autoComplete="confirmpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => props.togglePassword('confirmpassword')} style={{ display: props.showConfirmPasswordButton ? 'block' : 'none' }}>
                                        Show
                                    </button>
                              </div>
                            </div>


      
                        </div>

                      </div>
                      </div>
                      
                    </div>

                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                    onClick={props.BackStepTwo}
                    className=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Back
                    </button>
                    <button
                      type="submit"
                      className=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Next
                    </button>
                  </div>
                  {props.isErrorPasswordVisible?
                   <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>{props.isErrorPasswordText}</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                  :''
                  }  
                 

        </>
       
        )
    }
    export default StepFormTwo;