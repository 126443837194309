

import { CheckIcon } from '@heroicons/react/24/solid'
import {  useState,useEffect } from 'react'
import StepFormOne from '../../components/steps/stepformone.component';
import StepFormTwo from '../../components/steps/stepformtwo.component';
import StepFormComplete from '../../components/steps/stemformcomplete.component';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'



const AlreadyVerifiedPage =() =>{


   
  return (
    <>
      <div>
        <div className="uppercase">
      
          <main className='py-6'>
              <div className="bg-white py-24 sm:py-32">
                  <div className="mx-auto px-6 lg:px-8">
                      <div className="mx-auto max-w-2xl sm:text-center">
                          <p className="mt-6 text-3xl leading-8 text-gray-600 sm:max-w-xl lg:max-w-none">
                              Your account is now activated.
                              <a href="https://pentabook.pentagontecho.com/" className="text-indigo-500 underline">Click here</a> to go to the log-in page Pentabooks.
                          </p>
                      </div>
                  </div>
              </div>

          </main>
        </div>
      </div>
    </>
   
  )
}
export default AlreadyVerifiedPage;