

import { UserContext } from '../../contexts/user.context'
import { useEffect,useState,useContext } from 'react'
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet   } from '@react-pdf/renderer';
import { formatAmountDisplay } from '../../utils/ValidationField';
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const RptSellTransaction =(props) =>{

    const [isLoad, setLoad] = useState(true);

    const { Tax_types } = useContext(UserContext);

    
    const [PurchaseTransactionList, setPurchaseTransactionList] = useState([]);
    const [Pagination, setPagination]= useState([]);
    const { company_id } = useContext(UserContext);
    const [Searchdata, setInputValue] = useState('');
    const [allClass, setClass] = useState([]);

    const [withsummary, setWithSummary] = useState(false);

    const handleToggle  = (event) => {
      setWithSummary(event.target.checked);
      console.log(withsummary);
    };

       
    const CheckTax_VAT = ()=>{
      const item =JSON.parse(Tax_types).find(item => item.id === 3);
      return item.checked;
     
    }
    const [isCompletesubmit, setCompletesubmit] = useState(false);

    const calculateTotal = (listdata,propertyName) => {
      let total = 0;
      listdata.forEach(item => {
        total += parseFloat(item[propertyName]) || 0;
      });

      
      return (total !=null)?(total).toLocaleString() : '0.00';
    };

    const calculateTotalWithoutFormat = (listdata,propertyName) => {
      let total = 0;
      listdata.forEach(item => {
        total += parseFloat(item[propertyName]) || 0;
      });

      
      return total;
    };
  

    useEffect( () => {

      LoadClassList();

  }, []); 


  const styles = {
    container: {
      padding: 20,
      textTransform: 'capitalize' // Capitalize text
    },
    row: {
      flexDirection: 'row',
    borderBottomWidth: 1, // Add border bottom
    borderColor: 'gray', // Border color
 
     
    },
    label: {
      width: 200,
      fontWeight: 'bold',
    },
    value: {
      flex: 1,
      textTransform: 'capitalize' // Capitalize text
    },
    imageContainer: {
      marginBottom: 8,
      alignItems: 'center',
    },

    rowExpense: {
      flexDirection: 'row',
    
    },
    cell: {
      width: '20%', // Adjust the width of each cell as needed
      border: '1px solid black',
      fontSize: 8,
      padding: 2,
      textTransform: 'capitalize' // Capitalize text
    },
  };
  

     const [showPDF, setShowPDF] = useState(false);

    // const generatePDF = () => {
    //   setShowPDF(true);
    // };
    const [isNodata, setNodata] = useState(false);

    

    const defaultFormFields = {
        fromdate: '',
        todate:'',
        classification:''
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { fromdate,todate,classification} = formFields;
    

    const handleChange = (event) => {
        const { name, value } = event.target;
     
        
        setFormFields({ ...formFields, [name]: value });
      };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        ReportListExpense();
        setShowPDF(true);
       
      };

    const SearchExpense=() =>{
       
        ReportListExpense();
        setShowPDF(true);
    }
    const ReportListExpense = async (page = 1) => {

      setCompletesubmit(true);

      console.log(classification);

      console.log(fromdate);
     
      console.log(page);
        const postData = {
          "company_id": company_id,
          "startdate":fromdate,
          "enddate":todate,
          "classification":classification,
        //   "withsummary": withsummary,
          "page":page
        };


        console.log(postData);
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/report/sell_transaction', requestOptions)
        .then( async response => 
          {
             
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               setCompletesubmit(false);
            }else{
              const data = await response.json();
              console.log(data.data);
              setPurchaseTransactionList(data.data); 
             setPagination(data.pagination);
              props.setshowSummaryCreate(false);
              props.setshowListPending(true);
              setCompletesubmit(false);
              

              console.log(PurchaseTransactionList);
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);

        setCompletesubmit(false);
    } 


  const handleExport = async (page = 1) => {

  
      const postData = {
        "company_id": company_id,
        "startdate":fromdate,
        "enddate":todate,
        "classification":classification,
      //   "withsummary": withsummary,
        "page":page
      };


      console.log(postData);
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/report/sell_transaction', requestOptions)
      .then( async response => 
        {
           
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

           // const data = await response.json();
         
            // handleData(data.message);
             setNodata(false);
             setCompletesubmit(false);
          }else{
            const data = await response.json();
            console.log(data.data);
      


            
            const dataExcel = [];


            let AMOUNTOFGROSSSALES=0;
            let VATABLESALES=0;
            let VATABLEGOVERMENT=0;
            let VATABLEZERORATED=0;
            let VATEXEMPTSALES=0;
            let AMOUNTOFTAXABLESALES=0;
            let AMOUNTOFOUTPUTTAX=0;
            let AMOUNTOFGROSSTAXABLESALES=0;


            data.data.forEach(item => {

          
              item.expenses.forEach(expense => {

                AMOUNTOFGROSSSALES+=parseFloat((expense.vat_sales>0)?expense.vat_sales :0 )+parseFloat((expense.vat_gov>0)? expense.vat_gov : 0)+parseFloat((expense.vat_exempt >0)? expense.vat_exempt: 0)+parseFloat((expense.vat_zero_rated >0)? expense.vat_zero_rated :0);
                VATABLESALES +=parseFloat(expense.vat_sales);
                VATABLEGOVERMENT+=parseFloat((expense.vat_gov>0)? expense.vat_gov : 0);
                VATABLEZERORATED+=parseFloat(expense.vat_zero_rated);
                VATEXEMPTSALES+=parseFloat(expense.vat_exempt);
                AMOUNTOFTAXABLESALES+=(parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)+parseFloat(expense.vat_zero_rated)>0)? parseFloat(expense.vat_sales)+parseFloat(expense.vat_gov)+parseFloat(expense.vat_zero_rated) :0;
                AMOUNTOFOUTPUTTAX+=parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)*0.12;
                AMOUNTOFGROSSTAXABLESALES+=(parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)+parseFloat(expense.vat_zero_rated))+((parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0))*0.12);
                const objectData = { 
                  'TAXABLE MONTH': expense.date_trans,
                  'TAXPAYER IDENTIFICATION':expense.tin ,
                  'REGISTERED NAME':expense.seller ,
                  'CUSTOMER ADDRESS':expense.address ,
                  'AMOUNT OF GROSS SALES':formatAmountDisplay(parseFloat((expense.vat_sales>0)?expense.vat_sales :0 )+parseFloat((expense.vat_gov>0)? expense.vat_gov : 0)+parseFloat((expense.vat_exempt >0)? expense.vat_exempt: 0)+parseFloat((expense.vat_zero_rated >0)? expense.vat_zero_rated :0)) ,
                  'VATABLE SALES':formatAmountDisplay(parseFloat(expense.vat_sales)),
                  'VATABLE GOVERMENT':formatAmountDisplay(parseFloat((expense.vat_gov>0)? expense.vat_gov : 0)),
                  'VATABLE ZERO RATED':formatAmountDisplay(parseFloat(expense.vat_zero_rated)),
                  'VAT EXEMPT SALES':formatAmountDisplay(parseFloat(expense.vat_exempt)),
                  'AMOUNT OF TAXABLE SALES':formatAmountDisplay((parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)+parseFloat(expense.vat_zero_rated)>0)? parseFloat(expense.vat_sales)+parseFloat(expense.vat_gov)+parseFloat(expense.vat_zero_rated) :0),
                  'AMOUNT OF OUTPUT TAX':formatAmountDisplay(parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)*0.12),
                  'AMOUNT OF GROSS TAXABLE SALES':formatAmountDisplay((parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0)+parseFloat(expense.vat_zero_rated))+((parseFloat(expense.vat_sales)+parseFloat((expense.vat_gov !=null)? expense.vat_gov :0))*0.12))
                 };
                dataExcel.push(objectData);
         

              });
             

             
            });
          
            const objectData = { 
              'TAXABLE MONTH': 'GRAND TOTAL',
              'TAXPAYER IDENTIFICATION':'' ,
              'REGISTERED NAME':'',
              'CUSTOMER ADDRESS':'',
              'AMOUNT OF GROSS SALES': formatAmountDisplay(AMOUNTOFGROSSSALES),
              'VATABLE SALES': formatAmountDisplay(VATABLESALES),
              'VATABLE GOVERMENT': formatAmountDisplay(VATABLEGOVERMENT),
              'VATABLE ZERO RATED':formatAmountDisplay(VATABLEZERORATED),
              'VAT EXEMPT SALES':formatAmountDisplay(VATEXEMPTSALES),
              'AMOUNT OF TAXABLE SALES':formatAmountDisplay(AMOUNTOFTAXABLESALES),
              'AMOUNT OF OUTPUT TAX':formatAmountDisplay(AMOUNTOFOUTPUTTAX),
              'AMOUNT OF GROSS TAXABLE SALES': formatAmountDisplay(AMOUNTOFGROSSTAXABLESALES),
             };
            dataExcel.push(objectData);

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(dataExcel);

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Convert the workbook to binary array
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Use file-saver to save the file
            const now = new Date();
            const formattedDate = now.toISOString().replace(/T/, '_').replace(/\..+/, ''); // e.g., "2024-08-25_14:30:00"
            const filename = `SummaryListofSales${formattedDate}.xlsx`;
        
            // Use file-saver to save the file
            const blob = new Blob([wbout], { type: 'application/octet-stream' });
            saveAs(blob, filename);
            
          }
            
          }
        
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      setLoad(true);

      setCompletesubmit(false);
  } 

    const LoadClassList = async () => {



      const postData = {
        "search":Searchdata,
        "company_id": company_id,
        "type":'sales'
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
      
            setClass(data.data); 

         
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      setLoad(true);
  } 


    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-lg text-gray-700 font-bold">Report</h1>
            <p className="mt-2 text-lg text-gray-700 font-bold">
               Summary List of Sales
            </p>
          </div>

        </div>
       
        <form >
            <div class="max-w-xl bg-gray-50 p-6 rounded-lg shadow-md mt-10 mx-auto">
                <div class="grid grid-cols-8 gap-4">
                  <div class="col-span-4">
                    <div class="flex items-center">
                      <label for="fromdate" class="text-sm font-medium text-gray-700 mr-4">From Date</label>
                          <input type="date"
                          id="fromdate" 
                          name="fromdate"
                          class="w-full p-2 border rounded-md
                          border-gray-300 shadow-sm
                          focus:border-indigo-300 focus:ring
                          focus:ring-indigo-200 focus:ring-opacity-50"
                          value={fromdate}
                          required
                          onChange={handleChange}
                          />
                      </div>
                  </div> 
                  <div className="col-span-4">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">To Date</label>
                          <input
                            type="date"
                            id="todate" 
                            name="todate" 
                            className="w-full p-2 border 
                            rounded-md border-gray-300
                            shadow-sm focus:border-indigo-300 
                            focus:ring focus:ring-indigo-200
                              focus:ring-opacity-50"
                              value={todate}
                              required
                              onChange={handleChange}
                          />
                      
                    </div>   
                  </div> 

                  {/* <div className="col-span-2">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">With Summary</label>
                      <input
                            type="checkbox"
                            id="withsummary" 
                            name="withsummary" 
                            className="w-full p-2 border 
                              focus:ring-opacity-50"
                              required
                              checked={withsummary}
                              onChange={handleToggle}
                          />
                      
                    </div>   
                  </div>  */}
                </div>
                <div class="mt-4 flex items-center justify-center">
                <button 
                        type="button"  onClick={() =>SearchExpense()} 
                        class="ml-2
                        bg-indigo-500
                        text-white px-4
                        py-2 rounded-md
                        hover:bg-indigo-600
                        focus:outline-none focus:ring
                        focus:ring-indigo-200
                        focus:ring-opacity-50 uppercase">Submit</button>

              <button
              type="button"  onClick={() =>handleExport()} 
              className="ml-2
              bg-white
              text-black"
              >


              <svg  className="-mr-0.5 h-12 w-12" viewBox="-4 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M5.112.006c-2.802 0-5.073 2.273-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.902-20.31h-31.946z" fill-rule="evenodd" clip-rule="evenodd" fill="#45B058"/><path d="M19.429 53.938c-.216 0-.415-.09-.54-.27l-3.728-4.97-3.745 4.97c-.126.18-.324.27-.54.27-.396 0-.72-.306-.72-.72 0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826c-.09-.126-.145-.27-.145-.414 0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646c.127-.18.324-.27.541-.27.378 0 .738.306.738.72 0 .144-.036.288-.127.414l-3.619 4.808 3.891 5.149c.09.126.125.27.125.414 0 .396-.324.738-.719.738zm9.989-.126h-5.455c-.595 0-1.081-.486-1.081-1.08v-10.317c0-.396.324-.72.774-.72.396 0 .721.324.721.72v10.065h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278.162.144.253.342.253.54 0 .36-.307.72-.703.72-.145 0-.307-.054-.432-.162-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z" fill="#ffffff"/><path d="M55.953 20.352v1h-12.801s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z" fill-rule="evenodd" clip-rule="evenodd" fill="#349C42"/><path d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801l-18.905-20.352z" opacity=".5" fill-rule="evenodd" clip-rule="evenodd" fill="#ffffff"/></svg>
              </button>
                </div>
            </div>

        </form>
        { isCompletesubmit?   <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">
          <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span class="sr-only">Loading...</span>
          </div> 
       </div>   : ''
      }
      <div className="max-w-screen-lg mx-auto mt-10 h-screen">
          
      <PDFViewer className="w-full h-full">
      <Document>
      <Page size={{ width: 1000, height: 1000 , orientation: 'landscape' }} style={styles.container}>
          <View >

          {PurchaseTransactionList.map((Rpt) => ( 
            <>
              <Text style={{ fontSize: 12 }}>SALE TRANSACTION </Text>
              <Text style={{ fontSize: 12 }}>RECONCILIATION OF LISTING FOR ENFORECEMENT</Text>

              <Text style={{ fontSize: 12 ,marginTop: 32 }}>TIN:  {Rpt.tin.toLowerCase()}</Text>
              <Text style={{ fontSize: 12 }}>OWNER'S NAME:  {Rpt.name.toLowerCase()}</Text>
              <Text style={{ fontSize: 12 }}>OWNER'S TRADE NAME: {Rpt.trade_name.toLowerCase()}</Text>
              <Text style={{ fontSize: 12 , marginBottom: 32 }}>OWNER'S ADDRESS: {Rpt.reg_address.toLowerCase()}</Text>


              <View  style={styles.row}>
            
                  
            <Text style={styles.cell}>TAXABLE MONTH</Text>
            <Text style={styles.cell}>TAXPAYER IDENTIFICATION</Text>
            <Text style={styles.cell}>REGISTERED NAME</Text>
            <Text style={styles.cell}>CUSTOMER ADDRESS</Text>
   
            {(CheckTax_VAT())?
          <>
           
             <Text style={styles.cell}>AMOUNT OF GROSS SALES</Text> 
            <Text style={styles.cell}>VATABLE SALES</Text> 
            <Text style={styles.cell}>VAT GOVERMENT</Text>
            <Text style={styles.cell}>ZERO RATED SALES</Text>
             <Text style={styles.cell}>VAT EXEMPT SALES</Text> 
        
            <Text style={styles.cell}>AMOUNT OF TAXABLE SALES</Text>
            <Text style={styles.cell}>AMOUNT OF OUTPUT TAX</Text>
            <Text style={styles.cell}>AMOUNT OF GROSS TAXABLE SALES</Text>
          </>
            :''}
       
          </View>

              {Rpt.expenses.map((item) => ( 
               <>
               
               <View  style={styles.row}>
              <Text style={styles.cell}>{item.date_trans}</Text>
              <Text style={styles.cell}>{item.tin}</Text>
              <Text style={styles.cell}>{item.seller.toLowerCase()}</Text>
              <Text style={styles.cell}>{item.address.toLowerCase()}</Text>
              
              {(CheckTax_VAT())?
              <>
             
               <Text style={styles.cell}>{formatAmountDisplay(parseFloat((item.vat_sales>0)?item.vat_sales :0 )+parseFloat((item.vat_gov>0)? item.vat_gov : 0)+parseFloat((item.vat_exempt >0)? item.vat_exempt: 0)+parseFloat((item.vat_zero_rated >0)? item.vat_zero_rated :0))}</Text> 

              <Text style={styles.cell}>{formatAmountDisplay(parseFloat(item.vat_sales))}</Text>
              <Text style={styles.cell}>{formatAmountDisplay(parseFloat((item.vat_gov>0)? item.vat_gov : 0))}</Text> 
              <Text style={styles.cell}>{formatAmountDisplay(parseFloat(item.vat_zero_rated))}</Text>
              <Text style={styles.cell}>{formatAmountDisplay(parseFloat(item.vat_exempt))}</Text>
              <Text style={styles.cell}>{formatAmountDisplay((parseFloat(item.vat_sales)+parseFloat(parseFloat((item.vat_gov>0)? item.vat_gov : 0))+parseFloat(item.vat_zero_rated)>0)? parseFloat(item.vat_sales)+parseFloat(parseFloat((item.vat_gov>0)? item.vat_gov : 0))+parseFloat(item.vat_zero_rated) :0)}</Text>
              <Text style={styles.cell}>{formatAmountDisplay((parseFloat(item.vat_sales)+parseFloat((item.vat_gov>0)? item.vat_gov : 0))*0.12)}</Text>
              <Text style={styles.cell}>{formatAmountDisplay((parseFloat(item.vat_sales)+parseFloat(parseFloat((item.vat_gov>0)? item.vat_gov : 0))+parseFloat(item.vat_zero_rated))+((parseFloat(item.vat_sales)+parseFloat(parseFloat((item.vat_gov>0)? item.vat_gov : 0)))*0.12))}</Text>


           
               </>
               :''
              
              }
            </View>
               

                </>
               ))}
              <View  style={styles.row}>
             
             <Text style={styles.cell}>  Grand Total</Text>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             
            
             <Text style={styles.cell}>{formatAmountDisplay(calculateTotalWithoutFormat(Rpt.expenses,'vat_sales')+calculateTotalWithoutFormat(Rpt.expenses,'vat_gov')+calculateTotalWithoutFormat(Rpt.expenses,'vat_exempt')+calculateTotalWithoutFormat(Rpt.expenses,'vat_zero_rated'))}</Text>
              
             <Text style={styles.cell}>{calculateTotal(Rpt.expenses,'vat_sales')}</Text>
              <Text style={styles.cell}>{calculateTotal(Rpt.expenses,'vat_gov')}</Text> 

              <Text style={styles.cell}>{calculateTotal(Rpt.expenses,'vat_exempt')}</Text>   
              <Text style={styles.cell}>{calculateTotal(Rpt.expenses,'vat_zero_rated')}</Text>  
             <Text style={styles.cell}>{formatAmountDisplay((calculateTotalWithoutFormat(Rpt.expenses,'vat_sales')+calculateTotalWithoutFormat(Rpt.expenses,'vat_gov')+calculateTotalWithoutFormat(Rpt.expenses,'vat_zero_rated')))}</Text>  
             <Text style={styles.cell}>{formatAmountDisplay((calculateTotalWithoutFormat(Rpt.expenses,'vat_sales')+calculateTotalWithoutFormat(Rpt.expenses,'vat_gov'))*0.12)}</Text>  
             <Text style={styles.cell}>{formatAmountDisplay((calculateTotalWithoutFormat(Rpt.expenses,'vat_sales')+calculateTotalWithoutFormat(Rpt.expenses,'vat_gov')+calculateTotalWithoutFormat(Rpt.expenses,'vat_zero_rated'))+((calculateTotalWithoutFormat(Rpt.expenses,'vat_sales')+calculateTotalWithoutFormat(Rpt.expenses,'vat_gov'))*0.12))}</Text>  
          
               
           </View>
            </>
        ))}
          </View>
        </Page>
      </Document>
      </PDFViewer>


      </div>

      
       
    


       
          
      </div>);

}
export default RptSellTransaction;