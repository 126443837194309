import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import TaxReturnList from '../../layout/taxreturn/taxreturnlist.layout'
import TaxReturnCreate from '../../layout/taxreturn/taxreturncreate.layout'
import TaxReturnUpdate from '../../layout/taxreturn/taxreturnupdate.layout'
import { UserContext } from '../../contexts/user.context'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { formatAmount } from '../../utils/ValidationField';
const TaxReturnPage =() =>{

  
  const navigate = useNavigate();


    const [sidebarOpen, setSidebarOpen] = useState(false)

    const { company_id } = useContext(UserContext);
    const { user_id } = useContext(UserContext);
 
    const [selectedFile, setSelectedFile] = useState(null);

   
    let { type } = useParams();

    const [TaxReturnListPage, setTaxReturnListPage] = useState(true);
    const [TaxReturnCreatePage, setTaxReturnCreatePage] = useState(false);
    const [TaxReturnUpdatePage, setTaxReturnUpdatePage] = useState(false);

    const [MonthlyMode, setMonthlyMode] = useState(true);
    
    const [QuarterMode, setQuarter] = useState(false);

    const [AnuallyMode, setAnually] = useState(false);
    

    const [imgSupplemental, setImgSupplemental] = useState([]);

    const GotoCreatePage=() => {

      setTaxReturnListPage(false);

      setTaxReturnCreatePage(true);

      
      setTaxReturnUpdatePage(false);
    }
    const [Taxtype, setTaxtype] = useState('');
    

    const [isCompletesubmit, setCompletesubmit] = useState(false);


    const [convertDoneSupplemental, setConvertDoneSupplemental] = useState(false);

    const GotoUpdatePage = (image,date_paid,amount_paid,return_period,id) =>{

     
      setTaxReturnListPage(false);

      setTaxReturnCreatePage(false);

      
      setTaxReturnUpdatePage(true);

      setImgSupplemental(image);

      setSpecificItem('date_paid',date_paid);
      setSpecificItem('amount_paid',amount_paid.toLocaleString());
      setSpecificItem('return_period',return_period);
      setSpecificItem('tax_id',id);


      
    };


    const defaultFormFields = {
      date_paid:new Date().toISOString().split('T')[0],
      amount_paid:'',
      return_period:'Monthly',
      supplimental_fileupload:'',
      tax_id:'',
      YearSelected:'',
      Month:'',
      Quarter:''
    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { supplimental_fileupload,amount_paid,return_period,date_paid,tax_id,YearSelected,Month,Quarter} = formFields;
  

    const setSpecificItem = (itemName, itemValue) => {
      setFormFields(prevState => ({
          ...prevState,
          [itemName]: itemValue
      }));
  };




  const SupplementalhandleFileChange = async (e) => {
 

    setConvertDoneSupplemental(true);


    const files = e.target.files;
    console.log(files.length);
    for (let i = 0; i < files.length; i++) {

      
      const file = files[i];

      if (file.type.startsWith('image/') && /\.(jpg|jpeg|png)$/i.test(file.name)) {
        try {
          const options = {
            maxSizeMB: 1, // Set your desired maximum file size
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);

          const reader = new FileReader();
          reader.onload = (event) => {
            const imageUrl = event.target.result; // Data URL of the image
            const newImage = {
              imageUrl,
              name: `Image ${imgSupplemental.length + i + 1}`, // Generate a unique name for the new image
            };

            // Update state with the new image
            setImgSupplemental(prevItems => [...prevItems, newImage]);
          };

          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Compression failed:', error);
        }
      } else {
        // Handle case where file is not an image
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid file format. Only images (jpg, jpeg, png) are allowed.',
        });
      }
    }
    setConvertDoneSupplemental(false);
  };

  

  const removeItem = (indexToRemove) => {
    const updatedImgSupplemental = imgSupplemental.filter((img, index) => index !== indexToRemove);
    setImgSupplemental(updatedImgSupplemental);

    console.log(imgSupplemental);
  };



  const handleChange = (event) => {
    const { name, value } = event.target;

  
    if(name=='return_period'){
      if(value=='Monthly'){
        setMonthlyMode(true);
        setQuarter(false);
        setAnually(false);
      } 
      if(value=='Calendar Year'){
        setMonthlyMode(false);
        setQuarter(false);
        setAnually(true);
      }
      if(value=='Quarterly'){
        setMonthlyMode(false);
        setQuarter(true);
        setAnually(false);
      }
      if(value=='Annually'){
        setMonthlyMode(false);
        setQuarter(false);
        setAnually(true);
      }
    }
    const formattedValue =
      name === 'amount_paid' ? formatAmount(value) :value;
      
        setFormFields({ ...formFields, [name]: formattedValue });

  };


  const startYear = 2019;
  const endYear = 2030;
  const years = [];
  
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
       
    useEffect(() => {
    
        GetTypeTax();
        

      }, []);

    const GetTypeTax =() =>{

        

        switch (type) {
            case 'return_incometax':
                setTaxtype('INCOME TAX');
            break;
            case 'return_PT':
                setTaxtype('PERCENTAGE TAX (PT)');
            break;
            case 'return_VT':
                setTaxtype('VALUE ADDED TAX (VT)');
            break;
            case 'return_WE':
                setTaxtype('EXPANDED WITHHOLDING (WE)');
            break;
            case 'return_WC':
                setTaxtype('WITHHOLDING ON COMPENSATION (WC)');
            break;
            case 'return_DS':
                setTaxtype('DOCUMENTARY STAMP TAX (DS)');
            break;
            case 'return_WF':
                setTaxtype('FINAL WITHHOLDING TAX (WF)');
            break;
            default:
             
          }

    }


      
      
     

    
    

    const handleSubmit = async (event) => {
 
      event.preventDefault();
  
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
            
      if(date_paid ==''  || amount_paid ==''){

      
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please Complete The Data',
          
          })
          return;
      }

   
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to save",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {


          Swal.fire({
            title: "Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
              inputAutosubmit: false, // Disable auto-suggestion
            autocomplete: "off" // Disable browser suggestions
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              // Here, we return the value directly
              handleConfirmDisapproval( value)
              return value;
            },
      
           
            allowOutsideClick: () => !Swal.isLoading(),
            customClass: {
              confirmButton: 'bg-red-500' // Custom class for the confirm button
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const reason = result.value;
              // Log the reason to the console
              console.log("Reason entered:", reason);
    
              setCompletesubmit(true);
              const postData = {
                "user_id":user_id,
                "company_id": company_id,
                "tax_type": Taxtype,
                "period": return_period,
                "amount_paid": parseFloat(amount_paid.replace(/,/g, '')),
                "date_paid":date_paid,
                "suplemental_item":imgSupplemental,
                "remarks": reason,
                "month":(MonthlyMode)? Month: null,
                "quarter":(QuarterMode)? Quarter: null,
                "year":YearSelected,
              };
          
              setCompletesubmit(true);
    
              // console.log(postData);
              // return;
      
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/tax/return/store', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
                    
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'create new entry successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })  
                    setTaxReturnListPage(true);
    
                    setTaxReturnCreatePage(false);
    
                    setImgSupplemental([]);
                    resetFormFields();
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
              
    
                  
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
    
              }  
            
            })
         
      
         
     
          
        
  
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })
     
    }; 


    const handleSubmitUpdate = async (event) => {
      event.preventDefault();
  
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
            
      if(date_paid ==''  || amount_paid ==''){

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please Complete The Data',
          
          })
          return;
      }
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to update",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {


          Swal.fire({
            title: "Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
              inputAutosubmit: false, // Disable auto-suggestion
            autocomplete: "off" // Disable browser suggestions
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              // Here, we return the value directly
              handleConfirmDisapproval( value)
              return value;
            },
      
           
            allowOutsideClick: () => !Swal.isLoading(),
            customClass: {
              confirmButton: 'bg-red-500' // Custom class for the confirm button
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const reason = result.value;
              // Log the reason to the console
              console.log("Reason entered:", reason);
    
              setCompletesubmit(true);
              const postData = {
                "user_id":user_id,
                "id": tax_id,
                "company_id": company_id,
                "tax_type": Taxtype,
                "period": return_period,
                "amount_paid": parseFloat(amount_paid.replace(/,/g, '')),
                "date_paid":date_paid,
                "suplemental_item":imgSupplemental,
                "remarks": reason
                
              };
          
              setCompletesubmit(true);
    
            //    console.log(postData);
            //  return;
      
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/tax/return/update', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
                    
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'create new entry successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })  
                    setTaxReturnListPage(true);
    
                    setTaxReturnCreatePage(false);
                    setTaxReturnUpdatePage(false);
    
                    setImgSupplemental([]);
                    resetFormFields();
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
              
    
                  
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
    
              }  
            
            })
         
      
         
     
          
        
  
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })
     
    }; 

    const handleConfirmDisapproval = ( reason) => {
      if (!reason) {
        Swal.showValidationMessage("Remark is required!", () => {
          // Custom function to prevent closing the modal
          Swal.getPopup().querySelector('input').focus(); // Focus on the input field
        });
        return false; // Prevents closing the modal
      } else {
      //  DisapprovedExpense(ItemID, reason);
        return true; // Allows closing the modal
      }
    }; 
      const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };

    
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4 ">
            <div className="flex h-16 shrink-0 items-center   mt-10">
              <img
                className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Tax Returns & Payout'} subactivepage={type}  />
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                   {TaxReturnListPage? <TaxReturnList Taxtype={Taxtype} GotoCreatePage={GotoCreatePage} GotoUpdatePage={GotoUpdatePage} /> :''}

                   {TaxReturnCreatePage? <TaxReturnCreate Taxtype={Taxtype} 
                   imgSupplemental={imgSupplemental} isCompletesubmit={isCompletesubmit}
                    convertDoneSupplemental={convertDoneSupplemental} 
                    supplimental_fileupload={supplimental_fileupload}
                     SupplementalhandleFileChange={SupplementalhandleFileChange}
                      removeItem={removeItem} handleSubmit={handleSubmit} handleChange={handleChange}
                      company_id={company_id} amount_paid={amount_paid} date_paid={date_paid} return_period={return_period} 
                      years={years} YearSelected={YearSelected} Month={Month} Quarter={Quarter} MonthlyMode={MonthlyMode} QuarterMode={QuarterMode} AnuallyMode={AnuallyMode}
                      /> :''}

                      {TaxReturnUpdatePage? <TaxReturnUpdate 
                      imgSupplemental={imgSupplemental} Taxtype={Taxtype}  isCompletesubmit={isCompletesubmit}
                    convertDoneSupplemental={convertDoneSupplemental} 
                    supplimental_fileupload={supplimental_fileupload}
                     SupplementalhandleFileChange={SupplementalhandleFileChange}
                      removeItem={removeItem} handleSubmitUpdate={handleSubmitUpdate} handleChange={handleChange}
                      company_id={company_id} amount_paid={amount_paid} date_paid={date_paid} return_period={return_period}
                  
                      /> : ''}
                     
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>


      
  </>);

}
export default TaxReturnPage;