import { Fragment,useEffect, useState } from 'react'
import { UserGroupIcon } from '@heroicons/react/20/solid'
import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import { UserContext } from '../../contexts/user.context'

import { CalculatorIcon, ReceiptPercentIcon } from '@heroicons/react/20/solid'
import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useContext} from 'react';
import { useNavigate } from 'react-router-dom'

import { formatAmountDisplay } from '../../utils/ValidationField'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Dashboard =() =>{

  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false)



  const [isLoad, setLoad] = useState(true);



  const [isLoadSales, setLoadSales] = useState(true);

  
const { privelegeUser } = useContext(UserContext);
useEffect( () => {
  setLoad(false);
  LoadDashboard();
  LoadAllReceipt();
  LoadAllSummary();
  setLoad(true);
  setLoadSales(false);
  LoadAllSalesReceipt();
  setLoadSales(true);
  // console.log(privelegeUser);
}, []); 

const [expense, setExpense] = useState('');
const [sale, setSale] = useState('');
const [income, setIncome] = useState('');
const [usercount, setUsercount] = useState('');
const [miscellaneous, setmiscellaneous] = useState('');


const stats = [
  { id: 1, name: 'Expense', stat: '₱'+expense, icon: ReceiptPercentIcon, change: '5.4%', changeType: 'increase' },
  { id: 2, name: 'Sales', stat: '₱'+sale, icon: BanknotesIcon, change: '122', changeType: 'increase' },
  // { id: 3, name: 'Net Income', stat: '₱'+income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), icon: CalculatorIcon, change: '3.2%', changeType: 'decrease' },
  // { id: 3, name: 'miscellaneous', stat: '₱'+miscellaneous.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), icon: CurrencyDollarIcon, change: '3.2%', changeType: 'decrease' },
  { id: 4, name: 'User', stat:  usercount, icon: UserGroupIcon, change: '3.2%', changeType: 'decrease' },
]

const { company_id } = useContext(UserContext);


const LoadDashboard = async () => {

  const postData = {
    "company_id":company_id,
  //   "status":'Pending'
  };


  // console.log('company_id'+JSON.parse(localStorage.getItem('company_id')).value)

  // console.log('jwtToken'+JSON.parse(localStorage.getItem('jwtToken')).value)




  const requestOptions = {
    method: 'POST',
    headers: 
    { 'Content-Type': 'application/json',
    'Accept': 'application/json', 
     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
    body: JSON.stringify(postData)
  };

  
await fetch(process.env.REACT_APP_API_URL+'api/list_total', requestOptions)
  .then( async response => 
    {
        // console.log(response.status);
      // Check the status code
      if (response.status === 422) {
        // API call succeeded

        const data = await response.json();
        // console.log(data);  
      
         
      }else{
        const data = await response.json();

        setExpense(data.expense)
        setSale(data.sales)
        setIncome(data.income)
        setUsercount(data.user);
        setmiscellaneous(data.miscellaneous);
        // setListSummary(data); 
        //  props.setshowSummaryCreate(false);
        //  props.setshowListPending(true);
        
      }
    }
    
  )
  .then(data => {
    // Handle the response
 
    // console.log(data);
  
  })
  .catch(error => {
    // Handle any errors
    // console.log(error);
 
  });

} 
const [Searchdata, setInputValue] = useState('');
const [isNodata, setNodata] = useState(false);

const [allReceipt, setallReceipt] = useState([]);
const [Pagination, setPagination]= useState([]);
const LoadAllReceipt = async (page = 1) => {


  // console.log(page);
    const postData = {
      "company_id": company_id,
      "searchdata":Searchdata,
      "status":'all',
      "type":'expense',
      "perpage": 5,
      "page":page
    };
   
    
    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

 await fetch(process.env.REACT_APP_API_URL+'api/expense_list', requestOptions)
    .then( async response => 
      {
        //  console.log(response);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

         // const data = await response.json();
       
          // handleData(data.message);
           setNodata(false);
           
        }else{
          const data = await response.json();
          // console.log(data.data);
          setallReceipt(data.data); 
           setPagination(data.pagination);
  
       
          
        }
      }
      
    )
    .then(data => {
      // Handle the response
   
  
   
    })
    .catch(error => {
      // Handle any errors
   
    });
   
} 


const [allReceiptSales, setallReceiptSales] = useState([]);
const LoadAllSalesReceipt = async (page = 1) => {


  // console.log(page);
    const postData = {
      "company_id": company_id,
      "searchdata":Searchdata,
      "status":'all',
      "type":'sales',
      "perpage": 5,
      "page":page
    };
   
    
    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

 await fetch(process.env.REACT_APP_API_URL+'api/expense_list', requestOptions)
    .then( async response => 
      {
        //  console.log(response);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

         // const data = await response.json();
       
          // handleData(data.message);
           setNodata(false);
           
        }else{
          const data = await response.json();
        console.log(data.data);
           setallReceiptSales(data.data); 
          //  setPagination(data.pagination);
  
       
          
        }
      }
      
    )
    .then(data => {
      // Handle the response
   
  
   
    })
    .catch(error => {
      // Handle any errors
   
    });
   
} 

const [allActive, setallActive] = useState([]);

const LoadAllSummary = async (page = 1) => {


    const postData = {
      "company_id": company_id,
      "searchdata":Searchdata,
      "status":'active',
      "type":'expense',
      "perpage": 5,
      "page":page
    };
   
    
    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

 await fetch(process.env.REACT_APP_API_URL+'api/expense_list', requestOptions)
    .then( async response => 
      {
     
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

         // const data = await response.json();
       
          // handleData(data.message);
           setNodata(false);
           
        }else{
          const data = await response.json();
      
          setallActive(data.data); 

       
          
        }
      }
      
    )
    .then(data => {
      // Handle the response
   
  
   
    })
    .catch(error => {
      // Handle any errors
   
    });
   
} 



  return (
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen} />

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4 ">
            <div className="flex h-16 shrink-0 items-center   mt-10">
              <img
                className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation activepage={'Dashboard'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
           
                  {/* stats */}
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {stats.map((item) => (
                    <div
                      key={item.id}
                      className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                    >
                      <dt>
                        <div className="absolute rounded-md bg-indigo-500 p-3">
                          <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <p className="ml-16 truncate text-md font-medium text-gray-500 uppercase">{item.name}</p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">{(item.name.toLowerCase() !='user')? '₱'+formatAmountDisplay(item.stat): item.stat}</p>
                        <p
                          className={classNames(
                            item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                            'ml-2 flex items-baseline text-sm font-semibold'
                          )}
                        >
              
                        </p>
                        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                          <div className="text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              View all<span className="sr-only"> {item.name} stats</span>
                            </a>
                          </div>
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
                {/* end stats */}

    
            </div>
            <div className="sm:flex sm:items-center mt-4">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-indigo-500 capitalize">Recent Expense</h1>
          
              </div>
              {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  onClick={() => navigate('/expense/receipt')}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">

                  <svg  className="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                  New Entry
                </button>
              </div> */}
            </div>
            <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>

                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Series
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date Transaction
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Encoder
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Seller
                          </th>

                      
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Total
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Tin
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Classification
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                      {allReceipt.map((user) => (
                          <tr key={user.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                                {user.series} 
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {user.created_at} </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.encoder}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{user.seller}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(user.totalamount)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.tin}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{user.classification}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                              user.status==='pending'? 'For approval': 'Approved'

                              
                            }</td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
         
                    

                  </div>
                </div>
              </div>
            </div>

            <div className="sm:flex sm:items-center mt-4">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-indigo-500 capitalize">Recent Sales</h1>
          
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                {/* <button
                  type="button"
                  onClick={() => navigate('/sales/list')}
                 className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">

                  <svg  className="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                  New Entry
                </button> */}
              </div>
            </div>
      
            <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>

                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Series
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date Transaction
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Encoder
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Seller
                          </th>

                      
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Total
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Tin
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Classification
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                      {allReceiptSales.map((user) => (
                          <tr key={user.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                                {user.series} 
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {user.created_at} </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.encoder}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{user.seller}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(user.totalamount)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.tin}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{user.classification}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                              user.status==='pending'? 'For approval': 'Approved'

                              
                            }</td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
         
                    

                  </div>
                </div>
              </div>
            </div>
            {/* <div className="sm:flex sm:items-center mt-4">
              <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-indigo-600 uppercase mb-4">Newest Expense Summary</h1>
          
              </div>
           
            </div>
            <div className= {isLoad? 'mt-2 flow-root uppercase ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                      <tr>

                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Date Transaction
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Encoder
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Seller
                        </th>
 

                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Amount
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Tin
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Classification
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                      {allActive.map((user) => (
                          <tr key={user.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {user.created_at} 
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.encoder}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.seller}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{user.totalamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.tin}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.classification}</td>
                         
                        </tr>
                        ))}
                      </tbody>
                    </table>
         
                    

                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}
export default Dashboard;