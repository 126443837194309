import { Fragment,useEffect, useState } from 'react'
import { UserGroupIcon } from '@heroicons/react/20/solid'
import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import { UserContext } from '../../contexts/user.context'

import { CalculatorIcon, ReceiptPercentIcon } from '@heroicons/react/20/solid'
import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useContext} from 'react';
import { useNavigate } from 'react-router-dom'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const ChangePassword =() =>{

 
const { company_id } = useContext(UserContext);

const navigate = useNavigate();

const [sidebarOpen, setSidebarOpen] = useState(false)

  console.log('company_id'+JSON.parse(localStorage.getItem('company_id')).value)

  console.log('jwtToken'+JSON.parse(localStorage.getItem('jwtToken')).value)


  const [ErrorNotSame, setErrorNotSame]= useState(false);



  const [ErrorComplete, setErrorComplete]= useState(false);


  const defaultFormFields = {

      newpassword: '',
      confirmpassword:''
  

    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { newpassword,confirmpassword } = formFields;


    const resetFormFields = () => {
        setFormFields(defaultFormFields);
        //('');
    };

    const [showCurentPasswordButton, setshowCurentPasswordButton] = useState(false);

     const [showConfirmPasswordButton, setshowConfirmPasswordButton] = useState(false);

    const [showNewPasswordButton, setshowNewPasswordButton] = useState(false);

    


    const handleChange = (event) => {
      const { name, value } = event.target;

      if(name==='currentpassword'){

          if(value !=''){
            setshowCurentPasswordButton(true);
          }else{
            setshowCurentPasswordButton(false);
          }

      }
      if(name==='newpassword'){

        if(value !=''){
          setshowNewPasswordButton(true);
        }else{
          setshowNewPasswordButton(false);
        }

    }
      if(name==='confirmpassword'){

          if(value !=''){
            setshowConfirmPasswordButton(true);
          }else{
            setshowConfirmPasswordButton(false);
          }

      }
   
      const formattedValue =  value;
      setFormFields({ ...formFields, [name]: formattedValue });
    };

    const [isCurrentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setisConfirmPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setisNewPasswordVisible] = useState(false);

  const togglePassword = (value) => {

      if(value==='currentpassword'){

        setCurrentPasswordVisible(prevState => !prevState);

      }

      if(value==='newpassword'){

        setisNewPasswordVisible(prevState => !prevState);

      }
      
      if(value==='confirmpassword'){

        setisConfirmPasswordVisible(prevState => !prevState);
      }
      

  }
  // const verify = async () => {

      
  //     setErrorComplete(false);
  //     setErrorNotSame(false);
  //     if(newpassword ==''|| confirmpassword==''){

  //         setErrorComplete(true);
  //         return;
  //     }

  //     if(newpassword != confirmpassword ){

  //         setErrorNotSame(true);
  //         return;

  //     }
     
  // const postData = {
  //   "passwrd": newpassword,
  //   "link_activation": id,
  // };


  // const requestOptions = {
  //   method: 'POST',
  //   headers: 
  //   { 'Content-Type': 'application/json',
  //   'Accept': 'application/json', 
  //   },
  //   body: JSON.stringify(postData)
  // };

  // await fetch(process.env.REACT_APP_API_URL+'api/verify', requestOptions)
  // .then( async response => 
  //   {
  //       console.log(response.status);
  //     // Check the status code
  //     if (response.status === 422) {
  //       // API call succeeded

  //       const data = await response.json();
  //   
  //       // handleData(data.message);
       
  //     }else{
  //       const data = await response.json();
  //       console.log(data.message);  

  //       Swal.fire({
  //         position: 'top-end',
  //         icon: 'success',
  //         title: 'Verified Successfully',
  //         showConfirmButton: false,
  //         timer: 1500
  //     })  
  //     navigate('/verified');
  //     }
  //   }
    
  // )
  // .then(data => {
  //   // Handle the response
  //   console.log(data);
  // })
  // .catch(error => {
  //   // Handle any errors
  //   console.error(error);
  // });
      
    
  // }

  return (
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen} />

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4 ">
            <div className="flex h-16 shrink-0 items-center   mt-10">
              <img
                className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation activepage={'Changepassword'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
              <div className="sm:flex sm:items-center mb-5">
            <div className="sm:flex-auto">

                <h3 className="text-base font-semibold leading-6 text-gray-900">Change Password</h3>
            </div>
        </div>
        <div className="px-4 sm:px-0">
    

                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-3">
                        <p className="mt-1 text-sm leading-6 text-gray-600">  Create a strong password: 
                        Use a combination of uppercase and lowercase letters, 
                        numbers, special characters and atleast 8 character. Avoid 
                        using easily guessable information like your name or birthdate. </p>
                    </div>
            
                    <div className="col-span-9">

                        <form action="">
                            <div className="grid  grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12">
                                        
                                        <div className="sm:col-span-3 ">
                                            <div className="relative mb-6" data-te-input-wrapper-init>

                                                <label for="currentpassword" className="block text-sm font-medium leading-6 text-gray-900">Current Password  <span className='text-red-500'>*</span></label>
                                                <div className="mt-2 relative">
                                                    <input type={isCurrentPasswordVisible ? 'text' : 'password'}
                                                    name="currentpassword" 
                                                    id="currentpassword" 
                                                    onChange={handleChange}
                                                    autoComplete="currentpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                    <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => togglePassword('currentpassword')} style={{ display: showCurentPasswordButton ? 'block' : 'none' }}>
                                                        Show
                                                    </button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <div className="relative mb-6" data-te-input-wrapper-init>
                    

                                                <label for="newpassword" 
                                                className="block text-sm font-medium leading-6 text-gray-900">New Password  <span className='text-red-500'>*</span></label>
                                                <div className="mt-2 relative">
                                                      <input 
                                                      type={isNewPasswordVisible ? 'text' : 'password'}
                                                      name="newpassword" 
                                                      id="newpassword" 
                                                      onChange={handleChange}
                                                        autocomplete="text" class="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>       
                                                      
                                                      <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => togglePassword('newpassword')} style={{ display: showNewPasswordButton ? 'block' : 'none' }}>
                                                              Show
                                                          </button>
                                                  </div>
                                                  
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <div className="relative mb-6" data-te-input-wrapper-init>

                                                <label for="confirmpassword" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password <span className='text-red-500'>*</span></label>
                                                <div className="mt-2 relative">
                                                    <input id="confirmpassword"
                                                     required name="confirmpassword" 
                                                     type={isConfirmPasswordVisible ? 'text' : 'password'}
                                                     autocomplete="text"
                                                     onChange={handleChange}
                                                     className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>  
                                                       <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => togglePassword('confirmpassword')} style={{ display: showConfirmPasswordButton ? 'block' : 'none' }}>
                                                              Show
                                                          </button>     
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                </div>       
                    
                                <div className="flex items-center uppercase">
                                        <button onclick="changepassword()" type="button"  id="btnsasve" className="UTMAvo rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-blue-500" >Submit</button>
                                        
                                
                                </div> 
                                </form>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}


export default ChangePassword;