import { Outlet,Navigate } from "react-router-dom"


import { UserContext } from "../contexts/user.context";
import {  useContext  } from "react";


const PrivateRoutes =() =>{
    
    const { currentUser } = useContext(UserContext);

    return(
        (currentUser !==null) ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes