const StepFormOne= (props) => {

    const VTRadion = [
        { id: 'vt', title: 'vat' },
        { id: 'nvt', title: 'non vat' },
 
      ]
return (
    <>
    <div className="space-y-12 uppercase">
        <div className="border-b border-gray-900/10 pb-12 mt-10">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                <div className="sm:col-span-4">
                    <label htmlFor="Companyname" className="block text-sm font-medium leading-6 text-gray-900">
                        Owner Name  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="company"
                        value={props.company}
                        required
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Type of Business <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                    <select
                        name="businessType"
                        id="businessType"
                        value={props.businessType}
                        required
                        onChange={props.handleChange}

                        
                        autoComplete="typebusinnes"
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                        <option ></option>
                        <option value="Retail Businesses">Retail Businesses</option>
                        <option value="Food and Beverage Businesses">Food and Beverage Businesses</option>
                        <option value="Services">Services</option>
                        <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Construction and Real Estate">Construction and Real Estate</option>
                        <option value="Technology and IT Services">Technology and IT Services</option>
                        <option value="Agriculture and Farming">Agriculture and Farming</option>
                        <option value="Financial Services">Financial Services</option>
                        <option value="Transportation and Logistics">Transportation and Logistics</option>
                        <option value="Wholesale Trade">Wholesale Trade</option>
                        <option value="Entertainment and Media">Entertainment and Media</option>
                        <option value="Energy and Utilities">Energy and Utilities</option>
                        <option value="Telecommunications">Telecommunications</option>
                        <option value="E-commerce">E-commerce</option>
                        <option value="Healthcare and Pharmaceuticals">Healthcare and Pharmaceuticals</option>
                        <option value="Education and Training">Education and Training</option>
                        <option value="Environmental Services">Environmental Services</option>
                        <option value="Environmental Services">Legal and Professional Services</option>
                        <option value="Environmental Services">Non-profit Organizations</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900 opacity-0">
                        VAT
                    </label>
                    <div className="mt-2">
                        <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {VTRadion.map((RadioItem) => (
                                <div key={RadioItem.id} className="flex items-center">
                                <input
                                    id={RadioItem.id}
                                    name="notification-method"
                                    type="radio"
                                    value={RadioItem.id}
                                    defaultChecked={RadioItem.id === props.SelectedVAT}
                                    // checked={props.selectedOption === RadioItem.id}
                                    onChange={props.handleVATChange}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor={RadioItem.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                    {RadioItem.title}
                                </label>
                                </div>
                            ))}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                        <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={props.email}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label htmlFor="Mobile" className="block text-sm font-medium leading-6 text-gray-900">
                        Mobile  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                        <input
                        id="mobile"
                        name="mobile"
                        type="text"
                        autoComplete="mobile"
                        required
                        maxlength="14"
                        value={props.mobile}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label htmlFor="Mobile" className="block text-sm font-medium leading-6 text-gray-900">
                        Landline
                    </label>
                    <div className="mt-2">
                        <input
                        id="landline"
                        name="landline"
                        type="number"
                        autoComplete="landline"
                        value={props.landline}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 sm:col-start-1">
                    <label htmlFor="Province" className="block text-sm font-medium leading-6 text-gray-900">
                        Province  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
          
                        <select
                        name="province"
                        id="province"
                        autoComplete="province"
                        value={props.province}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                        <option ></option>
                        
                        {props.ListProvince.map(province => (
                            <option key={province.id} value={province.provCode}>
                            {province.provDesc}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="City" className="block text-sm font-medium leading-6 text-gray-900">
                        City  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                    <select
                        name="city"
                        id="city"
                        autoComplete="city"
                        value={props.city}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                        <option ></option>
                        
                        {props.ListCity.map(city => (
                            <option key={city.id} value={city.citymunCode}>
                            {city.citymunDesc}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                        Barangay  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                    <select
                        name="barangay"
                        id="barangay"
                        autoComplete="barangay"
                        value={props.barangay}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                        <option ></option>
                        
                        {props.ListBarangay.map(barangay => (
                            <option key={barangay.id} value={barangay.brgyCode}>
                            {barangay.brgyDesc}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="col-span-full">
                    <label htmlFor="street" className="p-2.5 block text-sm font-medium leading-6 text-gray-900">
                        Street address  <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="street"
                        id="street"
                        autoComplete="street"
                        value={props.street}
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
            type="submit"
            className=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
            Next
            </button>
        </div>
    </>
   
    )
}
export default StepFormOne;